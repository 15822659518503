import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../component/header/Header';
import LearnContent from '../component/learn/LearnContent';

const Learn = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    return (
        <React.Fragment>
            <Header categoryIndex={screenMD ? 4 : 3}/>
            <LearnContent />
        </React.Fragment>
    );
}

export default Learn;