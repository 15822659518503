import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import imageIcon3 from '../../assets/images/home/home-content-image3.png';
import imageIcon4 from '../../assets/images/home/home-content-image4.png';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const HomeContentMainContent3 = () => {
    const { t } = useTranslation();
    const textData = {
        always_growing: t("always_growing"),
        company_history: t("company_history"),
        learn_more: t("learn_more"),
        background: t("background"),
        founded_in__interface: t("founded_in__interface"),
        early_days: t("early_days"),
        during_our__services: t("during_our__services"),
        milestones: t("milestones"),
        since_inception__for: t("since_inception__for"),
        real_time_global__updates: t("real_time_global__updates"),
        latest_flight__data: t("latest_flight__data"),
    };

    const navigate = useNavigate();
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const leftItemArr = [
        {
            title:textData.background,
            desc:textData.founded_in__interface,
        },
        {
            title:textData.early_days,
            desc:textData.during_our__services,
        },
        {
            title:textData.milestones,
            desc:textData.since_inception__for,
        },
    ];

    const handleViewMoreClick = () => {
        navigate(RoutePath.learn);
    };

    return (
        <Grid sx={{
            width:'100%',
            mt:'100px',
            padding:'0px 128px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'flex-start',
                    gap:'100px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'48px',
                        gap:'28px',
                    },
                }}>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        width:'470px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'100%',
                        },
                    }}>
                        <Typography sx={{
                            color:'#4AB3FF',
                            fontSize:'24px',
                            fontWeight:700,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'18px',
                            },
                        }}>
                            {textData.always_growing}
                        </Typography>
                        <Typography sx={{
                            color:'#0F1962',
                            fontSize:'36px',
                            fontWeight:700,
                            lineHeight:'46px',
                            textTransform:'uppercase',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'32px',
                            },
                        }}>
                            {textData.company_history}
                        </Typography>
                        <Button variant='text' sx={{
                            bgcolor:'transparent',
                            borderRadius:'60px',
                            border:'1px solid #0F289F',
                            textTransform:'none',
                            color:'#0F1962',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:700,
                            minHeight:'0px',
                            minWidth:'154px',
                            padding:'9px 20px',
                            mt:'18px',
                            "&:hover": {
                                bgcolor:'transparent',
                            },
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                padding:'9px 37px',
                            },
                        }} onClick={handleViewMoreClick}>
                            {textData.learn_more}
                        </Button>
                        {leftItemArr.map((item,index) => (
                            <Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                width:'100%',
                                mt:'48px',
                            }} key={index}>
                                <Box sx={{
                                    width:'100%',
                                    height:'1px',
                                    bgcolor:'#000000',
                                }} />
                                <Typography sx={{
                                    mt:'10px',
                                    color:'#000000',
                                    fontSize:'24px',
                                    fontStyle:'normal',
                                    fontWeight:700,
                                }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{
                                    mt:'8px',
                                    color:'#000000',
                                    fontSize:'14px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                }}>
                                    {item.desc}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        width:'428px',
                        // ml:'100px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'100%',
                        },
                    }}>
                        <CardMedia
                            component="img"
                            image={ imageIcon3 }
                            sx={{
                                width:'428px',
                                height:'520px',
                                objectFit:'cover',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'100%',
                                    height:'calc((100vw - 36px) * 413 / 339)',
                                },
                            }}
                        />
                        <Typography sx={{
                            mt:'10px',
                            color:'#0F1962',
                            fontSize:'24px',
                            fontStyle:'normal',
                            fontWeight:700,
                            textTransform:'capitalize',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'18px',
                            },
                        }}>
                            {textData.real_time_global__updates}
                        </Typography>
                        <CardMedia
                            component="img"
                            image={ imageIcon4 }
                            sx={{
                                mt:'10px',
                                width:'428px',
                                height:'166px',
                                objectFit:'cover',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'100%',
                                    height:'calc((100vw - 36px) * 132 / 339)',
                                },
                            }}
                        />
                        <Typography sx={{
                            mt:'10px',
                            color:'#0F1962',
                            fontSize:'24px',
                            fontStyle:'normal',
                            fontWeight:700,
                            textTransform:'capitalize',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'18px',
                            },
                        }}>
                            {textData.latest_flight__data}
                        </Typography>
                    </Box>
                </Grid>
            </Container>
        </Grid>
    );
}

export default HomeContentMainContent3;