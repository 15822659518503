import React from 'react';
import Grid from '@mui/material/Grid';
import ServiceContentHeader from './ServiceContentHeader';
import ServiceContentMainContent1 from './ServiceContentMainContent1';
import ServiceContentMainContent2 from './ServiceContentMainContent2';
import ServiceContentMainContent3 from './ServiceContentMainContent3';
import ServiceContentMainContent4 from './ServiceContentMainContent4';

const ServiceContentMain = () => {
    
    return (
        <Grid sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
        }}>
            <ServiceContentHeader />
            <ServiceContentMainContent1 />
            <ServiceContentMainContent2 />
            <ServiceContentMainContent3 />
            <ServiceContentMainContent4 />
        </Grid>
    );
}

export default ServiceContentMain;