import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Grid from '@mui/material/Grid';
import ServiceContentMain from './ServiceContentMain';
import Footer from '../footer/Footer';

const ServiceContent = () => {
    const theme = useTheme();

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <Grid sx={{
            pt:'88px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                pt:'60px',
            },
        }}>
            <ServiceContentMain />
            <Footer />
        </Grid>
    );
}

export default ServiceContent;