import React from 'react';
import { BrowserRouter,Route, Routes } from "react-router-dom";
import RoutePath from '../tools/RoutePath';
import Home from "../pages/Home";
import Product from '../pages/Product';
import Api from '../pages/Api';
import Service from '../pages/Service';
import Learn from '../pages/Learn';

const MainRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={RoutePath.home} element={<Home/>}/>
                <Route path={RoutePath.product} element={<Product/>}/>
                <Route path={RoutePath.api} element={<Api/>}/>
                <Route path={RoutePath.service} element={<Service/>}/>
                <Route path={RoutePath.learn} element={<Learn/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default MainRoutes;