import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import imageIcon12 from '../../assets/images/home/home-content-image12.png';
import imageIcon13 from '../../assets/images/home/home-content-image13.png';
import imageIcon14 from '../../assets/images/home/home-content-image14.png';

const HomeContentMainContent8Item = (props) => {
    const {
        isMiddle,image,title,readMore,
    } = props;
    const theme = useTheme();

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',   
            width:'318px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'100%',
            },
        }}>
            <CardMedia
                component="img"
                image={ image }
                sx={{
                    width:'100%',
                    objectFit:'cover',
                    height:!isMiddle ? '338px' : '406px',
                    mt:isMiddle ? '0px' : '33px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'0px',
                        height:!isMiddle ? 'calc((100vw - 36px) * 356 / 334)' : 'calc((100vw - 36px) * 428 / 334)',
                    },
                }}
            />
            <Box sx={{
                mt:'8px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            }}>
                <Box sx={{
                    bgcolor:'#000000',
                    width:'36px',
                    height:'10px',
                }} />
                <Box sx={{
                    bgcolor:'#000000',
                    width:'2px',
                    height:'10px',
                    ml:'5px',
                }} />
                <Box sx={{
                    bgcolor:'#000000',
                    width:'50px',
                    height:'10px',
                    ml:'5px',
                }} />
            </Box>
            <Box sx={{
                bgcolor:'#000000',
                mt:'6px',
                width:'100%',
                height:'1px',
            }} />
            <Typography sx={{
                width:'100%',
                color:'#000000',
                fontSize:'15px',
                fontWeight:400,
                textAlign:'center',
                mt:'5px',
            }}>
                {title}
            </Typography>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
            }}>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'8px',
                    fontWeight:400,
                    textAlign:'center',
                    textTransform:'uppercase',
                    mt:'22px',
                }}>
                    {readMore}
                </Typography>
                <Box sx={{
                    bgcolor:'#000000',
                    width:'100%',
                    height:'1px',
                    mt:'2px',
                }} />
            </Box>
        </Box>
    );
}

const HomeContentMainContent8 = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        become_better: t("become_better"),
        looking_forward: t("looking_forward"),
        all_is__offerings: t("all_is__offerings"),
        continuing_to__solutions: t("continuing_to__solutions"),
        all_will__sector: t("all_will__sector"),
        read_more: t("read_more"),
    };

    const itemArr = [
        {
            image:imageIcon12,
            title:textData.all_is__offerings,
        },
        {
            image:imageIcon13,
            title:textData.continuing_to__solutions,
        },
        {
            image:imageIcon14,
            title:textData.all_will__sector,
        },
    ];

    return (
        <Grid sx={{
            width:'100%',
            mt:'120px',
            mb:'80px',
            padding:'0px 88px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#4AB3FF',
                    fontSize:'24px',
                    fontWeight:700,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'18px',
                    },
                }}>
                    {textData.become_better}
                </Typography>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'36px',
                    fontWeight:700,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'24px',
                    },
                }}>
                    {textData.looking_forward}
                </Typography>
                <Box sx={{
                    mt:'48px',
                    width:'100%',
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',  
                    gap:'22px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        flexDirection:'column',
                        mt:'24px',
                    },
                }}>
                    {itemArr.map((item,index) => (
                        <HomeContentMainContent8Item 
                            isMiddle={index === 1 ? true : false}
                            image={item.image}
                            title={item.title}
                            readMore={textData.read_more}
                            key={index}
                        />
                    ))}
                </Box>
            </Container>
        </Grid>
    );
}

export default HomeContentMainContent8;