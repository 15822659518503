import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import headerIcon from '../../assets/images/header/header-banner.png';
import headerMobileIcon from '../../assets/images/home/banner-mobile.png';
import HeaderMenu from '../header/HeaderMenu';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const HomeContentHeader = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        book_demo: t("book_demo"),
        contact_sales: t("contact_sales"),
        your_gateway__solutions: t("your_gateway__solutions"),
        trust_pilot__review: t("trust_pilot__review"),
    };

    const navigate = useNavigate();

    const handleBookDemoClick = () => {
        navigate(RoutePath.product,{state: {isBookDemo:true}});
    }
    const handleContactSalesClick = () => {
        navigate(RoutePath.api,{state: {isContactSales:true}});
    }

    return (
        <React.Fragment>
            <Grid sx={{
                width:'100%',
                position:'relative',
                display:'flex',
                justifyContent:'center',
                alignItems:'flex-start',
            }}>
                <CardMedia
                    component="img"
                    image={ screenMD ? headerMobileIcon : headerIcon }
                    sx={{
                        position:'absolute',
                        top:'0px',
                        left:'0px',
                        width:'100%',
                        height:'100%',
                        objectFit:'cover',
                    }}
                />
                <Grid container sx={{
                    padding:'0px 100px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        padding:'0px',
                    },
                }}>
                    <Container sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'start',
                        alignItems:'start',
                        position:'relative',
                        '@media': {
                            pl:'0px',
                            pr:'0px',
                            maxWidth:'1000px',
                        }
                    }}>
                        {screenMD ? 
                            '' :
                            <Box sx={{
                                width:'100%',
                                mt:'20px',
                            }}>
                                <HeaderMenu categoryIndex={-1} />
                            </Box>
                        }
                        
                        <Box sx={{
                            mt:'70px',
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            gap:'15px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                mt:'0px',
                                padding:'88px 18px 0px 18px',
                            },
                        }}>
                            {screenMD ? 
                                <svg xmlns="http://www.w3.org/2000/svg" width="211" height="75" viewBox="0 0 211 75" fill="none">
                                    <path d="M105.309 39.7226H100.028L98.8578 36.6255C98.4655 35.5999 96.6531 34.6754 94.8407 34.6754H90.1406L88.389 39.7226H83.1073L90.7898 19.7703H97.5999L105.316 39.7226H105.309ZM97.1062 31.2342L94.1915 23.5489L91.2767 31.2342H97.113H97.1062Z" fill="white"/>
                                    <path d="M112.404 39.7226H107.311V19.7703H112.404V39.7226Z" fill="white"/>
                                    <path d="M121.128 39.7226H116.035V19.7703H121.128V39.7226Z" fill="white"/>
                                    <path d="M133.639 28.2855H131.596C130.426 28.2855 129.649 28.9197 129.649 29.7497V39.7224H124.59V28.4541C124.59 26.0858 126.375 24.8442 129.743 24.8442H133.632V28.2855H133.639Z" fill="white"/>
                                    <path d="M152.216 36.3352C152.216 38.5821 150.464 39.7224 147.577 39.7224H140.997C137.886 39.7224 136.391 38.5281 136.391 36.4769V28.2112C136.391 26.0385 137.98 24.8442 141.057 24.8442H147.509C150.329 24.8442 152.209 25.9913 152.209 28.3327V36.3352H152.216ZM147.157 30.2152C147.157 29.0952 145.73 28.2855 143.884 28.2855H141.45V36.261H147.157V30.2152Z" fill="white"/>
                                    <path d="M171.503 36.531C171.503 38.6767 169.948 39.7293 167.094 39.7293H159.899C156.849 39.7293 155.584 38.7306 155.584 36.6052V24.8511H160.642V35.1477C160.642 35.8765 161.163 36.2678 162.164 36.2678H164.822C165.857 36.2678 166.445 35.8562 166.445 35.1207V24.8511H171.503V36.5377V36.531Z" fill="white"/>
                                    <path d="M191.021 39.7223H185.962V30.5525C185.962 29.284 184.339 28.2854 182.561 28.2854H179.869V39.7223H174.81V28.2314C174.81 26.0385 176.623 24.8374 179.612 24.8374H186.294C188.985 24.8374 191.028 26.0317 191.028 28.4001V39.7156L191.021 39.7223Z" fill="white"/>
                                    <path d="M210.213 36.0923C210.213 38.4809 208.401 39.7292 205.283 39.7292H199.089C195.944 39.7292 194.26 38.5821 194.26 36.3892V28.805C194.26 26.2207 195.917 24.851 199.19 24.851H205.155V19.7769H210.213V36.0923ZM205.155 36.261V30.3569C205.155 29.2098 203.437 28.2854 201.618 28.2854H199.319V36.261H205.155Z" fill="white"/>
                                    <path d="M100.244 64.3373C100.244 66.5572 98.1072 67.8257 94.8949 67.8257H84.0947V64.3643H95.1857V61.6788C95.1857 60.4845 93.5288 59.5061 91.2633 59.5061H88.7002C85.5217 59.5061 84.0609 58.5075 84.0609 56.3348V51.2337C84.0609 49.061 85.7448 47.8667 89.0518 47.8667H100.237V51.3079H89.1195V56.0649H95.3412C98.3574 56.0649 100.237 57.3064 100.237 59.5061V64.3373H100.244Z" fill="white"/>
                                    <path d="M121.094 63.9998C121.094 66.5571 119.085 67.8256 115.42 67.8256H109.002C105.242 67.8256 103.328 66.4828 103.328 63.9998V51.8745C103.328 49.3847 105.174 47.8733 109.036 47.8733H115.487C118.794 47.8733 121.094 49.2903 121.094 51.8745V63.9998ZM116.035 54.0472C116.035 52.4886 114.189 51.3145 112.113 51.3145H108.387V64.3641H116.035V54.0472Z" fill="white"/>
                                    <path d="M140.219 59.5128H129.453V67.8324H124.394V52.0298C124.394 49.2971 126.436 47.8801 130.068 47.8801H140.895V51.3213H133.017C130.974 51.3213 129.453 52.4212 129.453 53.5413V56.0783H140.219V59.5195V59.5128Z" fill="white"/>
                                    <path d="M158.404 51.3145H152.96V67.8256H147.543V53.946C147.543 52.4076 145.663 51.3145 143.553 51.3145H142.092V47.8733H158.404V51.3145Z" fill="white"/>
                                    <path d="M27.1525 19.7769C33.4892 30.728 39.7177 41.497 46.068 52.4684C45.5134 52.4684 45.1347 52.4684 44.756 52.4684C42.5784 52.4684 40.4075 52.4347 38.2299 52.4886C37.4522 52.5089 37.0397 52.2525 36.661 51.5845C33.5501 46.1393 30.3986 40.7143 27.1863 35.1544C26.9429 35.5322 26.74 35.8156 26.5641 36.1125C23.5547 41.3081 20.5385 46.4969 17.5561 51.7127C17.2248 52.2862 16.8731 52.4954 16.2171 52.4886C13.6473 52.4549 11.0774 52.4751 8.23706 52.4751C14.5805 41.5172 20.8226 30.7213 27.1525 19.7836V19.7769Z" fill="white"/>
                                    <path d="M31.8391 19.6891C37.8377 19.6891 43.6334 19.4867 49.4088 19.7566C53.6693 19.959 56.7869 22.4016 58.5858 26.2544C60.3915 30.1342 60.0128 33.9735 57.47 37.408C56.3677 38.8992 54.7852 40.0327 53.3041 41.443C55.2856 44.8842 65.8085 63.8513 68.0131 67.6637C67.6006 67.6974 67.3166 67.7446 67.0393 67.7446C64.6994 67.7446 62.3527 67.7716 60.0128 67.7244C59.6273 67.7176 59.0795 67.4747 58.9037 67.1711C55.5967 61.5302 43.9377 40.586 40.6645 34.9249C40.6172 34.8439 40.6037 34.7427 40.5293 34.4998C41.564 34.4998 42.5446 34.4998 43.5252 34.4998C45.0671 34.4998 46.6022 34.5065 48.1441 34.4998C50.3082 34.4863 51.8501 33.0693 51.9245 31.0518C51.9989 28.9804 50.5179 27.4082 48.3132 27.334C46.4061 27.2665 44.499 27.3137 42.5919 27.3137C40.7186 27.3137 38.8453 27.334 36.972 27.2935C36.6542 27.2935 36.1943 27.1383 36.0523 26.9089C34.6524 24.5743 33.2999 22.2059 31.8459 19.6958L31.8391 19.6891Z" fill="white"/>
                                    <path d="M43.5454 70.167C43.5454 69.4046 43.5725 68.6489 43.6198 67.8999C41.5775 68.3182 39.4675 68.5342 37.3034 68.5342C19.9975 68.5342 5.91742 54.4859 5.91742 37.2191C5.91742 19.9523 19.9975 5.90405 37.3034 5.90405C54.6093 5.90405 67.4586 18.7647 68.6015 35.0059C70.4748 34.3582 72.4224 33.8521 74.4242 33.508C72.5509 14.7163 56.6178 0 37.3034 0C16.7378 0 0 16.7 0 37.2191C0 57.7382 16.7378 74.4382 37.3034 74.4382C39.4945 74.4382 41.6383 74.2358 43.728 73.8714C43.6063 72.6501 43.5454 71.4086 43.5454 70.1603V70.167Z" fill="white"/>
                                </svg> :
                                <React.Fragment>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 125 125" fill="none">
                                    <path d="M45.5957 33.2102C56.2366 51.5999 66.6958 69.6837 77.3594 88.1073C76.4282 88.1073 75.7922 88.1073 75.1562 88.1073C71.4995 88.1073 67.8541 88.0507 64.1974 88.1413C62.8914 88.1753 62.1987 87.7447 61.5627 86.623C56.3388 77.4792 51.0467 68.3693 45.6525 59.0328C45.2437 59.6673 44.903 60.1432 44.6077 60.6418C39.5541 69.3664 34.4892 78.0797 29.4811 86.8383C28.9246 87.8014 28.3341 88.1527 27.2325 88.1413C22.9171 88.0847 18.6017 88.1187 13.832 88.1187C24.4843 69.7176 34.9662 51.5886 45.5957 33.2215V33.2102Z" fill="white"/>
                                    <path d="M53.4658 33.0628C63.5389 33.0628 73.2713 32.7229 82.9696 33.1761C90.1241 33.516 95.3593 37.6177 98.3801 44.0875C101.412 50.6027 100.776 57.0498 96.5063 62.8172C94.6553 65.3212 91.9979 67.2248 89.5108 69.5929C92.8382 75.3715 110.509 107.222 114.211 113.624C113.518 113.681 113.041 113.76 112.576 113.76C108.646 113.76 104.706 113.805 100.776 113.726C100.129 113.715 99.2091 113.307 98.9139 112.797C93.3606 103.324 73.7823 68.1539 68.2858 58.6475C68.2063 58.5115 68.1836 58.3415 68.0587 57.9336C69.7962 57.9336 71.4429 57.9336 73.0896 57.9336C75.6788 57.9336 78.2567 57.945 80.8459 57.9336C84.48 57.911 87.0692 55.5315 87.1941 52.1437C87.3191 48.6651 84.832 46.0251 81.1299 45.9005C77.9274 45.7871 74.7249 45.8665 71.5224 45.8665C68.3767 45.8665 65.231 45.9005 62.0853 45.8325C61.5515 45.8325 60.7793 45.5719 60.5408 45.1866C58.1901 41.2662 55.9188 37.2891 53.4772 33.0741L53.4658 33.0628Z" fill="white"/>
                                    <path d="M73.1235 117.828C73.1235 116.547 73.1689 115.278 73.2484 114.021C69.8188 114.723 66.2756 115.086 62.6416 115.086C33.5807 115.086 9.9368 91.4952 9.9368 62.5C9.9368 33.5048 33.5807 9.91434 62.6416 9.91434C91.7024 9.91434 113.279 31.5106 115.199 58.7835C118.344 57.6958 121.615 56.846 124.976 56.2681C121.831 24.7122 95.0753 0 62.6416 0C28.1069 0 0 28.0434 0 62.5C0 96.9566 28.1069 125 62.6416 125C66.321 125 69.921 124.66 73.4301 124.048C73.2257 121.997 73.1235 119.913 73.1235 117.816V117.828Z" fill="white"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="214" height="81" viewBox="0 0 214 81" fill="none">
                                    <path d="M37.8405 33.7038H28.9712L27.0065 28.503C26.3479 26.7807 23.3044 25.2284 20.2609 25.2284H12.3682L9.42691 33.7038H0.557617L13.4584 0.198975H24.8943L37.8518 33.7038H37.8405ZM24.0652 19.4498L19.1707 6.54415L14.2761 19.4498H24.0766H24.0652Z" fill="white"/>
                                    <path d="M49.7535 33.7038H41.2021V0.198975H49.7535V33.7038Z" fill="white"/>
                                    <path d="M64.4029 33.7038H55.8516V0.198975H64.4029V33.7038Z" fill="white"/>
                                    <path d="M85.4126 14.4984H81.9829C80.0183 14.4984 78.7123 15.5635 78.7123 16.9571V33.7039H70.2178V14.7816C70.2178 10.8046 73.2159 8.71973 78.8713 8.71973H85.4012V14.4984H85.4126Z" fill="white"/>
                                    <path d="M116.608 28.0159C116.608 31.789 113.667 33.7039 108.818 33.7039H97.7679C92.5439 33.7039 90.0342 31.6983 90.0342 28.2538V14.3737C90.0342 10.7253 92.7029 8.71973 97.8701 8.71973H108.704C113.44 8.71973 116.597 10.6459 116.597 14.5777V28.0159H116.608ZM108.113 17.7389C108.113 15.8581 105.717 14.4984 102.617 14.4984H98.5287V27.8912H108.113V17.7389Z" fill="white"/>
                                    <path d="M148.996 28.3444C148.996 31.9475 146.385 33.7151 141.592 33.7151H129.509C124.387 33.7151 122.264 32.0382 122.264 28.469V8.73096H130.758V26.0216C130.758 27.2453 131.633 27.9025 133.313 27.9025H137.776C139.514 27.9025 140.502 27.2113 140.502 25.9762V8.73096H148.996V28.3557V28.3444Z" fill="white"/>
                                    <path d="M181.771 33.7037H173.276V18.3053C173.276 16.1752 170.551 14.4982 167.564 14.4982H163.044V33.7037H154.55V14.4076C154.55 10.7251 157.593 8.70825 162.613 8.70825H173.833C178.353 8.70825 181.782 10.7138 181.782 14.6908V33.6924L181.771 33.7037Z" fill="white"/>
                                    <path d="M214 27.6078C214 31.6188 210.956 33.715 205.721 33.715H195.318C190.038 33.715 187.21 31.7888 187.21 28.1063V15.3706C187.21 11.031 189.992 8.73088 195.489 8.73088H205.505V0.210205H214V27.6078ZM205.505 27.891V17.9767C205.505 16.0505 202.621 14.4982 199.566 14.4982H195.705V27.891H205.505Z" fill="white"/>
                                    <path d="M29.3349 75.0381C29.3349 78.7658 25.7463 80.896 20.352 80.896H2.21596V75.0834H20.8404V70.5738C20.8404 68.5682 18.058 66.9253 14.2537 66.9253H9.94963C4.61215 66.9253 2.15918 65.2484 2.15918 61.5999V53.0339C2.15918 49.3854 4.9869 47.3799 10.5401 47.3799H29.3235V53.1585H10.6537V61.1466H21.1015C26.1665 61.1466 29.3235 63.2315 29.3235 66.9253V75.0381H29.3349Z" fill="white"/>
                                    <path d="M64.3468 74.4714C64.3468 78.7658 60.9739 80.8959 54.8188 80.8959H44.0416C37.7275 80.8959 34.5137 78.6411 34.5137 74.4714V54.1102C34.5137 49.9292 37.614 47.3911 44.0984 47.3911H54.9324C60.4856 47.3911 64.3468 49.7706 64.3468 54.1102V74.4714ZM55.8522 57.7587C55.8522 55.1413 52.752 53.1698 49.2656 53.1698H43.0082V75.0833H55.8522V57.7587Z" fill="white"/>
                                    <path d="M96.4625 66.9367H78.3832V80.9074H69.8887V54.371C69.8887 49.782 73.3183 47.4026 79.4166 47.4026H97.5981V53.1812H84.368C80.9384 53.1812 78.3832 55.0281 78.3832 56.909V61.1694H96.4625V66.948V66.9367Z" fill="white"/>
                                    <path d="M126.999 53.1698H117.857V80.8959H108.761V57.5887C108.761 55.0053 105.604 53.1698 102.06 53.1698H99.6074V47.3911H126.999V53.1698Z" fill="white"/>
                                    </svg>
                                </React.Fragment>
                            }
                        </Box>
                        <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            mt:'80px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                mt:'0px',
                                padding:'78px 18px 0px 18px'
                            },
                        }}>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'36px',
                                fontStyle:'normal',
                                fontWeight:700,
                                maxWidth:'500px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'19px',
                                    maxWidth:'318px',
                                },
                            }}>
                                {textData.your_gateway__solutions}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            gap:'28px',
                            mt:'30px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                padding:'0px 18px',
                                width:'calc(100% - 36px)',
                            },
                        }}>
                            <Button variant='text' sx={{
                                bgcolor:'#4AB3FF',
                                borderRadius:'60px',
                                textTransform:'none',
                                color:'#FFFFFF',
                                fontSize:'15px',
                                fontStyle:'normal',
                                fontWeight:700,
                                minHeight:'0px',
                                minWidth:'154px',
                                padding:'10px 25px',
                                "&:hover": {
                                    bgcolor:'#4AB3FF',
                                },
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    minHeight:'0px',
                                    minWidth:'0px',
                                    padding:'0px',
                                    width:'calc((100vw - 64px) / 2)',
                                    height:'36px',
                                },
                            }} onClick={handleBookDemoClick}>
                                {textData.book_demo}
                            </Button>
                            <Button variant='text' sx={{
                                bgcolor:'transparent',
                                border:'1px solid #FFFFFF',
                                borderRadius:'60px',
                                textTransform:'none',
                                color:'#FFFFFF',
                                fontSize:'15px',
                                fontStyle:'normal',
                                fontWeight:700,
                                minHeight:'0px',
                                minWidth:'154px',
                                padding:'10px 37px',
                                "&:hover": {
                                    bgcolor:'transparent',
                                },
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    minHeight:'0px',
                                    minWidth:'0px',
                                    padding:'0px',
                                    width:'calc((100vw - 64px) / 2)',
                                    height:'36px',
                                },
                            }} onClick={handleContactSalesClick}>
                                {textData.contact_sales}
                            </Button>
                        </Box>
                        <Typography sx={{
                            margin:'20px 0px 154px 0px',
                            color:'#FFFFFF',
                            fontSize:'12px',
                            fontStyle:'normal',
                            fontWeight:400,
                            maxWidth:'300px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                margin:'18px 18px 180px 18px',
                                fontSize:'16px',
                            },
                        }}>
                            {textData.trust_pilot__review}
                        </Typography>
                    </Container>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default HomeContentHeader;