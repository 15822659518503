import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import headerIcon from '../../assets/images/product/header-banner.png';
import headerMobileIcon from '../../assets/images/product/header-banner-mobile.png';
import headerStatisIcon from '../../assets/images/product/header-banner-statis.png';

const ProductContentHeader = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        customized_data__via: t("customized_data__via"),
        allroundsoft: t("allroundsoft"),
        api_interface__of: t("api_interface__of"),
        aviation_data: t("aviation_data"),
        try_this: t("try_this"),
        contact_sales: t("contact_sales"),
        lease_come__possible: t("lease_come__possible"),
    };

    const handleTryClick = () => {

    };
    const handleContactClick = () => {

    };

    return (
        <Grid sx={{
            width:'100%',
            position:'relative',
            display:'flex',
            justifyContent:'center',
            alignItems:'flex-start',
        }}>
            <CardMedia
                component="img"
                image={ screenMD ? headerMobileIcon : headerIcon }
                sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    objectFit:'cover',
                }}
            />
            <Grid container sx={{
                padding:'92px 128px 370px 128px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'48px 18px 0px 18px',
                },
            }}>
                <Container sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'start',
                    alignItems:'start',
                    position:'relative',
                    '@media': {
                        padding:'0px',
                        maxWidth:'1000px',
                    }
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="259" height="92" viewBox="0 0 259 92" fill="none">
                    <path d="M123.509 79.2689C123.509 82.004 120.876 83.567 116.918 83.567H103.611V79.3022H117.276V75.9934C117.276 74.5219 115.235 73.3165 112.443 73.3165H109.286C105.369 73.3165 103.57 72.0861 103.57 69.4092V63.1242C103.57 60.4473 105.644 58.9758 109.719 58.9758H123.5V63.2157H109.802V69.0766H117.468C121.184 69.0766 123.5 70.6063 123.5 73.3165V79.2689H123.509Z" fill="#0F1962"/>
                    <path d="M149.197 78.8534C149.197 82.0042 146.723 83.5671 142.207 83.5671H134.299C129.667 83.5671 127.308 81.9127 127.308 78.8534V63.9141C127.308 60.8465 129.583 58.9842 134.341 58.9842H142.29C146.364 58.9842 149.197 60.7301 149.197 63.9141V78.8534ZM142.965 66.591C142.965 64.6706 140.69 63.2241 138.132 63.2241H133.541V79.3023H142.965V66.591Z" fill="#0F1962"/>
                    <path d="M172.761 73.3246H159.496V83.5751H153.263V64.105C153.263 60.7381 155.78 58.9922 160.254 58.9922H173.594V63.2321H163.887C161.371 63.2321 159.496 64.5872 159.496 65.9672V69.0931H172.761V73.3329V73.3246Z" fill="#0F1962"/>
                    <path d="M195.166 63.2241H188.459V83.5671H181.784V66.4663C181.784 64.5709 179.468 63.2241 176.868 63.2241H175.069V58.9842H195.166V63.2241Z" fill="#0F1962"/>
                    <path d="M123.242 48.9411L121.801 45.1252C121.318 43.8616 119.085 42.7226 116.851 42.7226H111.061L108.902 48.9411H102.395L111.86 24.3582H120.251L129.758 48.9411H123.242ZM119.643 38.4828L116.052 29.0138L112.46 38.4828H119.651H119.643Z" fill="#0F1962"/>
                    <path d="M138.491 48.9411H132.216V24.3583H138.491V48.9411Z" fill="#0F1962"/>
                    <path d="M149.239 48.9411H142.965V24.3583H149.239V48.9411Z" fill="#0F1962"/>
                    <path d="M164.654 34.8501H162.138C160.696 34.8501 159.738 35.6316 159.738 36.6542V48.9414H153.506V35.058C153.506 32.14 155.705 30.6103 159.855 30.6103H164.646V34.8501H164.654Z" fill="#0F1962"/>
                    <path d="M187.543 44.7681C187.543 47.5364 185.385 48.9414 181.827 48.9414H173.719C169.886 48.9414 168.045 47.4699 168.045 44.9427V34.7587C168.045 32.0818 170.003 30.6103 173.794 30.6103H181.743C185.218 30.6103 187.534 32.0236 187.534 34.9083V44.7681H187.543ZM181.31 37.2278C181.31 35.8477 179.552 34.8501 177.277 34.8501H174.278V44.6766H181.31V37.2278Z" fill="#0F1962"/>
                    <path d="M211.306 45.0092C211.306 47.6529 209.39 48.9498 205.874 48.9498H197.008C193.25 48.9498 191.692 47.7194 191.692 45.1007V30.6187H197.925V43.305C197.925 44.2028 198.566 44.685 199.8 44.685H203.074C204.349 44.685 205.074 44.1779 205.074 43.2717V30.6187H211.306V45.0175V45.0092Z" fill="#0F1962"/>
                    <path d="M235.353 48.9417H229.121V37.6438C229.121 36.0808 227.121 34.8504 224.93 34.8504H221.614V48.9417H215.381V34.7839C215.381 32.0821 217.614 30.6023 221.297 30.6023H229.529C232.845 30.6023 235.362 32.0738 235.362 34.9918V48.9334L235.353 48.9417Z" fill="#0F1962"/>
                    <path d="M259 44.4684C259 47.4114 256.767 48.9494 252.926 48.9494H245.293C241.419 48.9494 239.344 47.5361 239.344 44.8342V35.4899C239.344 32.3059 241.386 30.6182 245.418 30.6182H252.767V24.3665H259V44.4684ZM252.767 44.6763V37.402C252.767 35.9887 250.651 34.8498 248.41 34.8498H245.577V44.6763H252.767Z" fill="#0F1962"/>
                    <path d="M33.4544 24.3665C41.2617 37.8592 48.9357 51.1275 56.7597 64.6451C56.0765 64.6451 55.6099 64.6451 55.1433 64.6451C52.4603 64.6451 49.7856 64.6036 47.1026 64.6701C46.1444 64.695 45.6361 64.3791 45.1695 63.5561C41.3367 56.8471 37.4538 50.1631 33.496 43.3128C33.1961 43.7784 32.9461 44.1276 32.7294 44.4934C29.0216 50.8947 25.3054 57.2877 21.6309 63.714C21.2226 64.4207 20.7893 64.6784 19.9811 64.6701C16.8148 64.6285 13.6485 64.6535 10.149 64.6535C17.9647 51.1524 25.6553 37.8509 33.4544 24.3748V24.3665Z" fill="#0F1962"/>
                    <path d="M39.2287 24.2586C46.6194 24.2586 53.7601 24.0092 60.8759 24.3417C66.1252 24.5911 69.9664 27.6006 72.1828 32.3476C74.4075 37.1278 73.9409 41.8582 70.808 46.0897C69.4498 47.927 67.5001 49.3236 65.6753 51.0611C68.1166 55.301 81.0817 78.6701 83.798 83.3672C83.2897 83.4087 82.9398 83.4669 82.5981 83.4669C79.7152 83.4669 76.8239 83.5002 73.9409 83.442C73.466 83.4337 72.791 83.1344 72.5744 82.7603C68.4999 75.8103 54.1351 50.0053 50.1023 43.0303C50.0439 42.9306 50.0273 42.8059 49.9356 42.5066C51.2105 42.5066 52.4186 42.5066 53.6268 42.5066C55.5266 42.5066 57.418 42.5149 59.3178 42.5066C61.9841 42.49 63.8839 40.7442 63.9755 38.2584C64.0672 35.7062 62.2424 33.7692 59.5261 33.6777C57.1764 33.5946 54.8267 33.6528 52.477 33.6528C50.1689 33.6528 47.8609 33.6777 45.5529 33.6278C45.1612 33.6278 44.5946 33.4366 44.4197 33.154C42.6949 30.2775 41.0284 27.3595 39.237 24.2669L39.2287 24.2586Z" fill="#0F1962"/>
                    <path d="M53.6515 86.4515C53.6515 85.5121 53.6848 84.581 53.7431 83.6582C51.2268 84.1736 48.6271 84.4397 45.9608 84.4397C24.6385 84.4397 7.29074 67.1311 7.29074 45.857C7.29074 24.5829 24.6385 7.27426 45.9608 7.27426C67.2831 7.27426 83.1144 23.1197 84.5225 43.1302C86.8306 42.3321 89.2303 41.7086 91.6966 41.2846C89.3886 18.1316 69.7578 0 45.9608 0C20.6224 0 0 20.5758 0 45.857C0 71.1381 20.6224 91.7139 45.9608 91.7139C48.6605 91.7139 51.3018 91.4645 53.8765 91.0156C53.7265 89.5109 53.6515 87.9812 53.6515 86.4432V86.4515Z" fill="#0F1962"/>
                    </svg>
                    <Typography sx={{
                        color:'#0F1962',
                        fontSize:'30px',
                        fontWeight:600,
                        lineHeight:'48px',
                        maxWidth:'681px',
                        whiteSpace:'pre-line',
                        mt:'56px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            mt:'28px',
                            fontSize:'18px',
                            lineHeight:'28px',
                            maxWidth:'330px',
                        },
                    }}>
                        {textData.customized_data__via}
                        <span style={{color:'#4AB3FF'}}>{textData.allroundsoft}</span>
                        {textData.api_interface__of}
                        <span style={{color:'#4AB3FF'}}>{textData.aviation_data}</span>
                    </Typography>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        mt:'90px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            mt:'48px',
                        },
                    }}>
                        <Button sx={{
                            textTransform:'none',
                            bgcolor:'#4AB3FF',
                            borderRadius:'57px',
                            color:'#FFFFFF',
                            fontSize:'15px',
                            fontWeight:700,
                            padding:'9px 50px',
                            '&:hover': {
                                bgcolor:'#4AB3FF',
                            },
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                padding:'0px',
                                width:'calc((100vw - 64px) / 2)',
                                minHeight:'36px',
                            },
                        }} onClick={handleTryClick}>
                            {textData.try_this}
                        </Button>
                        <Button sx={{
                            textTransform:'none',
                            bgcolor:'transparent',
                            borderRadius:'57px',
                            border:'1px solid #0F1962',
                            color:'#0F1962',
                            fontSize:'15px',
                            fontWeight:700,
                            padding:'9px 27px',
                            ml:'28px',
                            '&:hover': {
                                bgcolor:'transparent',
                            },
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                padding:'0px',
                                width:'calc((100vw - 64px) / 2)',
                                minHeight:'36px',
                            },
                        }} onClick={handleTryClick}>
                            {textData.contact_sales}
                        </Button>
                    </Box>
                    <Typography sx={{
                        color:'#0F1962',
                        fontSize:'16px',
                        fontWeight:400,
                        mt:'20px',
                        maxWidth:'336px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            mt:'10px',
                            fontSize:'12px',
                            maxWidth:'100%',
                        },
                    }}>
                        {textData.lease_come__possible}
                    </Typography>
                    {screenMD ? 
                        <CardMedia
                            component="img"
                            image={ headerStatisIcon }
                            sx={{
                                width:'calc(100% - 70px)',
                                height:'calc((100vw - 70px) * 249 / 305)',
                                objectFit:'cover',
                                margin:'166px 35px 48px 35px',
                            }}
                        /> : ''
                    }
                </Container>
            </Grid>
        </Grid>
    );
}

export default ProductContentHeader;