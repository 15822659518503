import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import image19Icon from '../../assets/images/home/home-content-image19.png';
import image20Icon from '../../assets/images/home/home-content-image20.png';

const HomeContentMainAnalysis = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        accurate_data_analysis: t("accurate_data_analysis"),
        total_revenue: t("total_revenue"),
        sequential_data: t("sequential_data"),
        customer_distribution: t("customer_distribution"),
    };

    const handleSwitchClick = () => {

    };

    return (
        <Grid sx={{
            mt:'158px',
            width:'100%',
            padding:'0px 128px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'36px',
                    fontWeight:700,
                    textTransform:'uppercase',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'32px',
                        lineHeight:'46px',
                    },
                }}> 
                    {textData.accurate_data_analysis}
                </Typography>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'flex-start',
                    gap:'18px',
                    mt:'32px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'18px',
                    },
                }}>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        borderRadius:'16px',
                        border:'1px solid #E7EAEE',
                        overflow:'hidden',
                        width:'627px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'100%',
                        },
                    }}>
                        <Box container sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'flex-start',
                            padding:'24px 24px 0px 24px',
                            width:'calc(100% - 48px)',
                        }}>
                            <Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                            }}>
                                <Typography sx={{
                                    color:'#72777B',
                                    fontSize:'14px',
                                    fontWeight:500,
                                }}> 
                                    {textData.total_revenue}
                                </Typography>
                                <Typography sx={{
                                    color:'#2D2D2D',
                                    fontSize:'26px',
                                    fontWeight:700,
                                }}> 
                                    {'$190,090.36'}
                                </Typography>
                            </Box>
                            <IconButton sx={{
                                bgcolor:'#F7F8F9',
                                borderRadius:'8px',
                                width:'32px',
                                height:'32px',
                            }} onClick={handleSwitchClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <g clip-path="url(#clip0_874_9847)">
                                    <path d="M4.66667 15.7181L2 13.0514L4.66667 10.3848" stroke="#2D2D2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14 9.05151V10.3848C14 11.0921 13.719 11.7704 13.219 12.2705C12.7189 12.7706 12.0406 13.0515 11.3333 13.0515H2" stroke="#2D2D2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.3333 1.05151L14 3.71818L11.3333 6.38485" stroke="#2D2D2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2 7.71802V6.38468C2 5.67744 2.28095 4.99916 2.78105 4.49907C3.28115 3.99897 3.95942 3.71802 4.66667 3.71802H14" stroke="#2D2D2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_874_9847">
                                    <rect width="16" height="16" fill="white" transform="translate(0 0.384766)"/>
                                    </clipPath>
                                </defs>
                                </svg>
                            </IconButton>
                        </Box>
                        <CardMedia 
                            component="img"
                            image={ image19Icon }
                            sx={{
                                width:'627px',
                                height:'313px',
                                objectFit:'cover',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'100%',
                                    height:'calc((100vw - 36px) * 313 / 627)',
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        borderRadius:'16px',
                        border:'1px solid #E7EAEE',
                        overflow:'hidden',
                        width:'285px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'100%',
                        },
                    }}>
                        <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            width:'calc(100% - 48px)',
                            padding:'24px 24px 0px 24px',
                        }}>
                            <Typography sx={{
                                color:'#000000',
                                fontSize:'14px',
                                fontWeight:500,
                            }}> 
                                {textData.sequential_data}
                            </Typography>
                            <Typography sx={{
                                color:'#2D2D2D',
                                fontSize:'22px',
                                fontWeight:700,
                            }}> 
                                {textData.customer_distribution}
                            </Typography>
                        </Box>
                        <CardMedia 
                            component="img"
                            image={ image20Icon }
                            sx={{
                                width:'285px',
                                height:'313px',
                                objectFit:'cover',
                                mt:'8px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'100%',
                                    height:'calc((100vw - 36px) * 313 / 285)',
                                },
                            }}
                        />
                    </Box>
                </Grid>
            </Container>
        </Grid>
    );
}

export default HomeContentMainAnalysis;
