import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const HomeContentMainContent1Item = (props) => {
    const {
        title,desc,index,
    } = props;
    const theme = useTheme();

    const [ hover,setHover ] = useState(false);

    const handleMouseEnterClick = () => {
        setHover(true);
    };
    const onMouseLeaveClick = () => {
        setHover(false);
    };

    return (
        <Box sx={{
            bgcolor:hover ? '#FFFFFF' : 'transparent',
            borderRadius:'29px',
            boxShadow:hover ? '0px 6.21138px 21.73984px 0px rgba(0, 0, 0, 0.10)' : 'none',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:'212px',
            minHeight:'160px',
            padding:'22px 22px 35px 22px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'calc((100vw - 140px) / 2)',
                minHeight:'160px',
                padding:'18px 19px',
            },
        }} onMouseEnter={handleMouseEnterClick} onMouseLeave={onMouseLeaveClick}>
            
            {
                index === 0 ?
                <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
                <path d="M47.9815 8.48739C48.8368 5.90899 48.6683 3.67179 47.2946 2.12561L47.1218 1.95286C45.5796 0.575119 43.3504 0.406681 40.7801 1.26615C38.551 1.95286 36.4946 3.33059 34.6111 5.04952L30.1529 9.51961L9.93108 4.70401C9.07572 4.53125 8.04325 4.70401 7.36069 5.39504L3.24807 9.52393C2.73399 10.0379 2.39271 10.9017 2.56119 11.9339C2.73399 12.7934 3.24807 13.4801 3.93063 13.8256L18.1606 21.5651L12.1602 27.5813L7.01941 26.2079C6.67814 26.0352 6.33254 26.0352 6.16406 26.0352C5.47718 26.0352 4.79462 26.3764 4.27623 26.8946L1.36024 29.9913C0.673362 30.5053 0.504883 31.369 0.504883 32.0557C0.504883 32.9152 1.01896 33.6019 1.53304 33.9474L9.417 39.7953L15.2447 47.7075C15.7587 48.3942 16.4456 48.7397 17.1325 48.7397H17.301C17.9878 48.7397 18.6747 48.3986 19.1888 47.8803L22.2733 44.7836C22.9601 44.0969 23.3014 43.0647 22.9601 42.2052L21.5864 37.0441L27.5868 31.0235L35.3023 45.2976C35.8164 46.157 36.5033 46.5025 37.0174 46.6753C37.3586 46.8481 37.5314 46.8481 37.7042 46.8481C38.3911 46.8481 38.9052 46.6753 39.4193 46.3341L43.5319 43.2374C44.3872 42.5507 44.7328 41.5185 44.56 40.4863L39.7605 19.1594L44.2188 14.6894C45.9338 12.9704 47.3032 10.906 47.9901 8.50034L47.9815 8.48739ZM35.933 18.1704L41.1516 40.983L38.0196 43.2288L28.2824 25.599L17.4997 36.3142L19.4091 43.0517L17.3226 45.2976L11.7584 37.8647L3.93063 31.991L6.18998 29.7451L12.9723 31.6454L23.755 20.9302L5.84006 11.2558L8.97204 8.14619L31.0601 13.3332L36.9742 7.63224C38.3609 6.25018 40.1018 5.21364 41.8428 4.52261C43.7565 3.83159 44.452 4.34986 44.7976 4.52261C44.9748 4.86813 45.3204 5.55916 44.7976 7.45948C44.2231 9.32958 43.1388 11.0053 41.6657 12.2967L35.9287 18.1704H35.933Z" fill="#4AB3FF"/>
                </svg> :
                (index === 1 ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="49" viewBox="0 0 59 49" fill="none">
                    <path d="M53.1666 9.59078L52.1111 5.16366C51.7539 3.66841 50.837 2.40237 49.5284 1.59478C48.2197 0.789847 46.6738 0.541971 45.1812 0.899125L8.95666 9.52415H6.25934C3.08759 9.52415 0.504883 12.1069 0.504883 15.2786V20.6013C0.504883 21.3955 1.14989 22.0406 1.94416 22.0406C5.83022 22.0406 8.99397 25.2016 8.99397 29.0904C8.99397 32.9791 5.83289 36.1402 1.94416 36.1402C1.14989 36.1402 0.504883 36.7852 0.504883 37.5795V42.9021C0.504883 46.0765 3.08759 48.6566 6.25934 48.6566H52.2923C55.4641 48.6566 58.0468 46.0765 58.0468 42.9021V15.2786C58.0468 12.4027 55.9252 10.0119 53.1666 9.59078ZM45.8475 3.69773C47.3908 3.32991 48.9447 4.28676 49.3125 5.82999L50.192 9.52415H21.3744L45.8449 3.69773H45.8475ZM3.38078 42.8995V38.9121C8.17571 38.2138 11.8672 34.0745 11.8672 29.0904C11.8672 24.1062 8.17305 19.9669 3.38078 19.2686V15.2813C3.38078 13.6954 4.6708 12.4054 6.25667 12.4054H9.23652V12.4187L9.29249 12.4054H17.4777V45.7807H6.25934C4.67347 45.7807 3.38344 44.4907 3.38344 42.9048L3.38078 42.8995ZM55.1682 42.8995C55.1682 44.4853 53.8782 45.7754 52.2923 45.7754H20.3563V12.4027H52.2923C53.8782 12.4027 55.1682 13.6927 55.1682 15.2786V42.8995Z" fill="#4AB3FF"/>
                    <path d="M34.0244 25.7799C34.0244 29.9112 37.3747 33.2615 41.506 33.2615C45.6373 33.2615 48.9876 29.9112 48.9876 25.7799C48.9876 21.6487 45.6373 18.2983 41.506 18.2983C37.3747 18.2983 34.0244 21.6487 34.0244 25.7799Z" fill="#4AB3FF"/>
                    </svg> :
                    (index === 2 ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="49" viewBox="0 0 56 49" fill="none">
                        <path d="M46.5541 0.739746H8.83965C4.04023 0.739746 0.268555 4.51143 0.268555 9.31143V33.3114C0.268555 38.1114 4.04023 41.8825 8.83965 41.8825H46.5541C51.3541 41.8825 55.1258 38.1108 55.1258 33.3114V9.31143C55.1258 4.51143 51.3541 0.739746 46.5541 0.739746ZM51.6969 33.3114C51.6969 36.0542 49.2969 38.4542 46.5541 38.4542H8.83965C6.09687 38.4542 3.69687 36.0542 3.69687 33.3114V9.31143C3.69687 6.56865 6.09687 4.16865 8.83965 4.16865H46.5541C49.2969 4.16865 51.6969 6.56865 51.6969 9.31143V33.3114ZM12.2686 45.3114C11.2402 45.3114 10.5541 45.997 10.5541 47.0253C10.5541 48.0536 11.2396 48.7397 12.2686 48.7397H43.1258C44.1541 48.7397 44.8402 48.0542 44.8402 47.0253C44.8402 45.9964 44.1547 45.3114 43.1258 45.3114H12.2686Z" fill="#4AB3FF"/>
                        <path d="M12.2686 21.3114C11.2402 21.3114 10.5541 21.997 10.5541 23.0259V33.3114C10.5541 34.3397 11.2396 35.0259 12.2686 35.0259C13.2975 35.0259 13.983 34.3403 13.983 33.3114V23.0259C13.983 21.997 13.2975 21.3114 12.2686 21.3114ZM22.5541 11.0259C21.5252 11.0259 20.8396 11.7114 20.8396 12.7403V33.312C20.8396 34.3403 21.5252 35.0265 22.5541 35.0265C23.583 35.0265 24.2686 34.3409 24.2686 33.312V12.7397C24.2686 11.7114 23.583 11.0253 22.5541 11.0253V11.0259ZM32.8396 24.7403C31.8107 24.7403 31.1252 25.4259 31.1252 26.4542V33.3114C31.1252 34.3397 31.8107 35.0259 32.8396 35.0259C33.8686 35.0259 34.5541 34.3403 34.5541 33.3114V26.4542C34.5541 25.4259 33.8686 24.7403 32.8396 24.7403ZM43.1252 14.4548C42.0969 14.4548 41.4107 15.1403 41.4107 16.1687V33.312C41.4107 34.3403 42.0963 35.0265 43.1252 35.0265C44.1541 35.0265 44.8396 34.3409 44.8396 33.312V16.1687C44.8396 15.1403 44.1541 14.4548 43.1252 14.4548Z" fill="#4AB3FF"/>
                        </svg> : 
                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="49" viewBox="0 0 55 49" fill="none">
                        <path d="M0.0332031 24.5151C0.0332031 37.8613 10.7252 48.7397 23.8839 48.7397C33.2667 48.7397 41.3045 43.244 45.2293 35.3192L40.8185 35.1695C37.3421 39.8044 33.0426 44.4034 23.1737 44.4034C12.7059 44.4034 4.18301 35.1318 4.18301 24.5151C4.18301 15.2067 10.7996 7.39398 19.4731 5.63652L19.4355 0.739746C8.40726 2.87027 0.0332031 12.7023 0.0332031 24.5151Z" fill="#4AB3FF"/>
                        <path d="M50.1997 12.7477L40.3309 18.542L25.8262 13.7197C24.144 13.2713 24.032 13.1216 23.0223 13.7573L18.6115 16.3362C17.2288 17.1216 18.0134 17.5323 20.7044 19.5884L28.5548 25.5325L12.9661 34.7656C14.6483 34.7279 24.9287 33.7568 27.06 32.4853L52.8547 17.2329C54.0885 16.5227 54.4992 14.915 53.7891 13.6811C53.3031 12.8957 52.4432 12.3729 51.5465 12.3729C51.0981 12.3729 50.6121 12.5226 50.2006 12.7468" fill="#4AB3FF"/>
                        <path d="M9.60173 28.0754L7.20938 29.4958L12.9293 33.6079L20.5179 29.1219L14.4249 27.2155C13.7524 26.9913 13.2664 26.8416 12.7804 26.8416C11.8828 26.8416 11.0974 27.2523 9.60173 28.0754Z" fill="#4AB3FF"/>
                        </svg>
                    )
                )
            }
            <Typography sx={{
                mt:'18px',
                color:'#000000',
                fontSize:'15px',
                fontWeight:700,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                },
            }}>
                {title}
            </Typography>
            <Typography sx={{
                display:hover ? 'block' : 'none',
                mt:'14px',
                color:'#000000',
                fontSize:'12px',
                fontWeight:400,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'10px',
                },
            }}>
                {desc}
            </Typography>
        </Box>
    );
}

const HomeContentMainContent1 = () => {
    const { t } = useTranslation();
    const textData = {
        our_services: t("our_services"),
        welcome_to__soft: t("welcome_to__soft"),
        view_more: t("view_more"),
        your_premier__clientele: t("your_premier__clientele"),
        global_flight__access: t("global_flight__access"),
        access_realtime__information: t("access_realtime__information"),
        advanced_ticketing_information: t("advanced_ticketing_information"),
        managing_crypto__centralized: t("managing_crypto__centralized"),
        customizable_data_solutions: t("customizable_data_solutions"),
        we_provide__firm: t("we_provide__firm"),
        secure_booking__payment: t("secure_booking__payment"),
        why_choose_soft: t("why_choose_soft"),
    };

    const navigate = useNavigate();
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const itemArr = [
        {
            title:textData.global_flight__access,
            desc:textData.access_realtime__information,
        },
        {
            title:textData.advanced_ticketing_information,
            desc:textData.managing_crypto__centralized,
        },
        {
            title:textData.customizable_data_solutions,
            desc:textData.we_provide__firm,
        },
        {
            title:textData.secure_booking__payment,
            desc:textData.managing_crypto__centralized,
        },
    ];

    const handleViewMoreClick = () => {
        navigate(RoutePath.service);
    };

    return (
        <Grid sx={{
            width:'100%',
            mt:'58px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
            },
        }}>
            <Container sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1200px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        flexDirection:'column',
                        padding:'0px 18px',
                    },
                }
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'flex-start',
                    zIndex:2,
                    ml:'110px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        ml:'0px',
                    },
                }}>
                    <Typography sx={{
                        color:'#4AB3FF',
                        fontSize:'24px',
                        fontWeight:700,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                        },
                    }}>
                        {textData.our_services}
                    </Typography>
                    <Typography sx={{
                        color:'#0F1962',
                        fontSize:'48px',
                        fontWeight:700,
                        lineHeight:'47px',
                        width:'270px',
                        mt:'20px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'32px',
                            mt:'0px',
                        },
                    }}>
                        {textData.welcome_to__soft}
                    </Typography>
                    <Button variant='text' sx={{
                        bgcolor:'transparent',
                        borderRadius:'60px',
                        border:'1px solid #0F289F',
                        textTransform:'none',
                        color:'#0F1962',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:700,
                        minHeight:'0px',
                        minWidth:'154px',
                        padding:'9px 20px',
                        mt:'108px',
                        "&:hover": {
                            bgcolor:'transparent',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            padding:'9px 41px',
                            mt:'30px',
                        },
                    }} onClick={handleViewMoreClick}>
                        {textData.view_more}
                    </Button>
                </Box>
                <Box sx={{
                    bgcolor:'#F3F9FF',
                    borderRadius:'38px',
                    position:'relative',
                    width:'890px',
                    height:'344px',
                    ml:'-90px',
                    zIndex:1,
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        margin:'18px 0px 0px 0px',
                        padding:'18px 14px',
                        borderRadius:'0px',
                        flexDirection:'column',
                        width:'auto',
                        height:'auto',
                    },
                }}>
                    <Typography sx={{
                        color:'#777F9B',
                        fontSize:'16px',
                        fontWeight:400,
                        lineHeight:'26px',
                        width:'608px',
                        mt:'38px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'auto',
                            mt:'0px',
                        },
                    }}>
                        {textData.your_premier__clientele}
                    </Typography>
                    {screenMD ? 
                        <Grid container sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            mt:'18px',
                        }}>
                            {itemArr.map((item,index) => (
                                <HomeContentMainContent1Item 
                                    title={item.title}
                                    desc={item.desc}
                                    index={index}
                                    key={index}
                                />
                            ))}
                        </Grid> : 
                        <Box sx={{
                            position:'absolute',
                            top:'151px',
                            left:'60px',
                            width:'850px',
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                        }}>
                            {itemArr.map((item,index) => (
                                <HomeContentMainContent1Item 
                                    title={item.title}
                                    desc={item.desc}
                                    index={index}
                                    key={index}
                                />
                            ))}
                        </Box>
                    }
                </Box>
            </Container>
        </Grid>
    );
}

export default HomeContentMainContent1;