import React, { useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import ApiContentHeader from './ApiContentHeader';
import ApiContentMainContent1 from './ApiContentMainContent1';
import ApiContentMainContent2 from './ApiContentMainContent2';
import ApiContentMainContent3 from './ApiContentMainContent3';

const ApiContentMain = (props) => {
    const {
        isContactSales,
    } = props;

    const targetRef = useRef(null);

    useEffect(() => {
        if(isContactSales !== undefined && isContactSales) {
            if(targetRef.current && targetRef.current.innerElementRef.current) {
                const element = targetRef.current.innerElementRef.current;
                setTimeout(() => {
                    element.scrollIntoView({ 
                        behavior: 'smooth',
                        block: 'center', // start center end nearest
                    });
                }, 100);
            }
        }
    }, []);
    
    return (
        <Grid sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
        }}>
            <ApiContentHeader />
            <ApiContentMainContent1 />
            <ApiContentMainContent2 ref={targetRef} />
            <ApiContentMainContent3 />
        </Grid>
    );
}

export default ApiContentMain;