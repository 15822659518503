import React from 'react';
import Grid from '@mui/material/Grid';
import LearnContentHeader from './LearnContentHeader';
import LearnContentMainContent from './LearnContentMainContent';

const LearnContentMain = () => {
    
    return (
        <Grid sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
        }}>
            <LearnContentHeader />
            <LearnContentMainContent />
        </Grid>
    );
}

export default LearnContentMain;