import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LearnContentMainContentLeft from './LearnContentMainContentLeft';
import LearnContentMainContentRight from './LearnContentMainContentRight';

const LearnContentMainContent = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        contact_allroundsoft: t("contact_allroundsoft"),
    };

    return (
        <Grid sx={{
            width:'100%',
            padding:'88px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'48px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1024px',
                }
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',    
                    gap:'72px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        flexDirection:'column',
                        gap:'48px',
                        width:'100%',
                    },
                }}>
                    <LearnContentMainContentLeft />
                    <LearnContentMainContentRight />
                </Box>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    position:'relative',
                    minHeight:'60px',
                    mt:'28px',
                }}>
                    <Box sx={{
                        bgcolor:'#D6D6D6',
                        position:'absolute',
                        top:'0px',
                        left:'0px',
                        width:'100%',
                        height:'1px',
                    }} />
                    <Box sx={{
                        bgcolor:'#D6D6D6',
                        position:'absolute',
                        bottom:'0px',
                        left:'0px',
                        width:'100%',
                        height:'1px',
                    }} />
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',    
                        gap:'20px',
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                        <path d="M13.2497 1.49987C14.2824 1.50283 15.2719 1.8989 16.0021 2.60157C16.7323 3.30424 17.1439 4.25641 17.147 5.25013V12.7499C17.1439 13.7436 16.7323 14.6958 16.0021 15.3984C15.2719 16.1011 14.2824 16.4972 13.2497 16.5001H5.45596C4.42328 16.4972 3.43378 16.1011 2.70356 15.3984C1.97335 14.6958 1.56175 13.7436 1.55867 12.7499V5.25013C1.56175 4.25641 1.97335 3.30424 2.70356 2.60157C3.43378 1.8989 4.42328 1.50283 5.45596 1.49987H13.2497ZM13.2497 0H5.45596C2.45512 0 0 2.3625 0 5.25013V12.7499C0 15.6375 2.45512 18 5.45596 18H13.2497C16.2505 18 18.7057 15.6375 18.7057 12.7499V5.25013C18.7057 2.3625 16.2505 0 13.2497 0Z" fill="#1C1F35"/>
                        <path d="M14.4192 5.25085C14.1879 5.25085 13.9619 5.18488 13.7696 5.06126C13.5774 4.93764 13.4275 4.76194 13.339 4.55637C13.2506 4.35081 13.2274 4.12461 13.2725 3.90638C13.3176 3.68815 13.429 3.48769 13.5925 3.33036C13.756 3.17303 13.9643 3.06588 14.1911 3.02247C14.4179 2.97906 14.6529 3.00134 14.8666 3.08649C15.0802 3.17164 15.2628 3.31583 15.3912 3.50084C15.5197 3.68584 15.5883 3.90335 15.5883 4.12585C15.5886 4.27368 15.5586 4.42011 15.4999 4.55675C15.4413 4.69338 15.3552 4.81753 15.2466 4.92206C15.1379 5.02659 15.0089 5.10944 14.8669 5.16587C14.7249 5.22229 14.5728 5.25117 14.4192 5.25085ZM9.35318 6.00059C9.96981 6.00059 10.5726 6.17654 11.0853 6.5062C11.598 6.83586 11.9976 7.30442 12.2336 7.85262C12.4696 8.40082 12.5313 9.00405 12.411 9.58602C12.2907 10.168 11.9938 10.7026 11.5578 11.1221C11.1217 11.5417 10.5662 11.8274 9.96142 11.9432C9.35663 12.059 8.72976 11.9996 8.16006 11.7725C7.59037 11.5454 7.10344 11.1609 6.76086 10.6675C6.41828 10.1741 6.23543 9.59409 6.23543 9.00072C6.23631 8.2053 6.56507 7.4427 7.14957 6.88025C7.73407 6.3178 8.52657 6.00144 9.35318 6.00059ZM9.35318 4.50072C8.42827 4.50072 7.52413 4.76464 6.7551 5.25911C5.98607 5.75357 5.38668 6.45638 5.03273 7.27865C4.67878 8.10091 4.58618 9.00571 4.76662 9.87863C4.94706 10.7515 5.39244 11.5534 6.04645 12.1827C6.70046 12.812 7.53372 13.2406 8.44085 13.4143C9.34799 13.5879 10.2883 13.4988 11.1428 13.1582C11.9973 12.8176 12.7276 12.2408 13.2415 11.5008C13.7553 10.7608 14.0296 9.89074 14.0296 9.00072C14.0296 7.80725 13.5369 6.66265 12.6599 5.81874C11.7829 4.97483 10.5934 4.50072 9.35318 4.50072Z" fill="#1C1F35"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
                        <path d="M23.2216 2.13281C22.377 2.49954 21.4833 2.74115 20.5689 2.85C21.5299 2.28768 22.2526 1.39433 22.6015 0.337507C21.6929 0.868407 20.7005 1.24091 19.6669 1.43907C19.2316 0.983222 18.7081 0.620586 18.1282 0.3732C17.5484 0.125813 16.9242 -0.00115656 16.2937 7.93799e-06C13.7409 7.93799e-06 11.6751 2.03438 11.6751 4.54218C11.6733 4.89101 11.7133 5.2388 11.7942 5.57812C9.96373 5.49236 8.17128 5.02573 6.53158 4.20808C4.89188 3.39043 3.44102 2.23976 2.27184 0.829693C1.86166 1.52068 1.64473 2.30908 1.64373 3.1125C1.64373 4.68749 2.46604 6.07968 3.7077 6.8953C2.97205 6.87785 2.25147 6.68328 1.60715 6.32811V6.38436C1.60715 8.58748 3.20203 10.4203 5.31291 10.8375C4.91596 10.9432 4.50691 10.9968 4.0961 10.9968C3.80461 10.9974 3.51377 10.9691 3.22783 10.9125C3.81465 12.7172 5.52259 14.0297 7.54575 14.0672C5.90178 15.3332 3.88343 16.0175 1.80791 16.0125C1.4395 16.0119 1.07144 15.99 0.705566 15.9468C2.817 17.2941 5.27137 18.0068 7.77654 18C16.2838 18 20.9315 11.0765 20.9315 5.07187C20.9315 4.87499 20.9264 4.67812 20.917 4.48593C21.8191 3.84471 22.5995 3.04786 23.2216 2.13281Z" fill="#1C1F35"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                        <path d="M16.7821 0H1.75972C0.938867 0 0.22168 0.590625 0.22168 1.40183V16.4575C0.22168 17.2732 0.938867 18 1.75972 18H16.7777C17.6029 18 18.2217 17.2683 18.2217 16.4575V1.40183C18.2265 0.590625 17.6029 0 16.7821 0ZM5.80128 15.0039H3.22262V6.98625H5.80128V15.0039ZM4.60114 5.76723H4.58266C3.75739 5.76723 3.22302 5.1529 3.22302 4.38388C3.22302 3.6008 3.77146 3.00094 4.61521 3.00094C5.45896 3.00094 5.97525 3.59638 5.99373 4.38388C5.99333 5.1529 5.45896 5.76723 4.60114 5.76723ZM15.2256 15.0039H12.6469V10.62C12.6469 9.56973 12.2716 8.85214 11.3387 8.85214C10.6259 8.85214 10.204 9.33429 10.0164 9.80397C9.9461 9.97272 9.92721 10.2025 9.92721 10.4372V15.0039H7.34855V6.98625H9.92721V8.10201C10.3025 7.56763 10.8887 6.79862 12.2528 6.79862C13.9455 6.79862 15.226 7.91438 15.226 10.3199L15.2256 15.0039Z" fill="#1C1F35"/>
                        </svg>
                    </Box>
                    <Typography sx={{
                        color:'#0F1962',
                        fontSize:'16px',
                        fontWeight:500,
                    }}>
                        {textData.contact_allroundsoft}
                    </Typography>
                </Grid>
            </Container>
        </Grid>
    );
}

export default LearnContentMainContent;