import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import ProductContentHeader from './ProductContentHeader';
import ProductContentMainContent1 from './ProductContentMainContent1';
import ProductContentMainContent2 from './ProductContentMainContent2';
import HomeContentMainContent5 from '../home/HomeContentMainContent5';

const ProductContentMain = (props) => {
    const {
        isBookDemo,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const targetRef = useRef(null);

    useEffect(() => {
        if(isBookDemo !== undefined && isBookDemo) {
            if(targetRef.current && targetRef.current.innerElementRef.current) {
                const element = targetRef.current.innerElementRef.current;
                setTimeout(() => {
                    element.scrollIntoView({ 
                        behavior: 'smooth',
                        block: 'center', // start center end nearest
                    });
                }, 100);
            }
        }
    }, []);

    return (
        <Grid sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
        }}>
            <ProductContentHeader />
            <ProductContentMainContent1 ref={targetRef} />
            <ProductContentMainContent2 />
            <HomeContentMainContent5 
                mt={'48px'}
                mb={screenMD ? '18px' : '129px'}
            />
        </Grid>
    );
}

export default ProductContentMain;