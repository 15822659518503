import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab, { tabClasses } from "@mui/material/Tab";

const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className={`${tabsClasses.indicatorSpan}`} /> }}
    />
  ))(({ theme }) => ({
    backgroundColor:'#FFFFFF',
    borderRadius:'76px',
    boxShadow:'0px 2.5px 41.66667px 0px rgba(77, 151, 139, 0.20)',
    display:'flex',
    justifyContent:'space-between',
    alignContent:'center',
    alignItems:'center',
    width:'308px',
    minHeight:'60px',
    padding:'5px 28px',
    marginTop:'25px',
    [`& .${tabsClasses.indicator}`]: {
      borderRadius:'44px',
      height:'44px',
      display: "flex",
      justifyContent: "center",
      alignItems:'center',
      backgroundColor: "#4AB3FF",
      zIndex: theme.zIndex.Tab - 1,
    },
    [`& .${tabsClasses.indicatorSpan}`]: {
      maxWidth: 40,
      height: "1px",
      width: "100%",
      backgroundColor: "transparent"
    }
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    gap:'8px',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    color: '#4AB3FF',
    zIndex: 2,
    width:'calc(100% / 2)',
    height:'44px',
    minHeight:'44px',
    borderRadius:'44px',
    "&.Mui-selected": {
      color: "#FFFFFF"
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)"
    }
  })
);

const ProductContentMainContent2Item = (props) => {
    const {
        title,desc,price,supports,type,btnTitle,purchaseOnClick,
    } = props;
    const theme = useTheme();
    
    return (
        <Box sx={{
            bgcolor:type === 1  ? '#1A4DB7' : '#FFFFFF',
            borderRadius:'8px',
            boxShadow:'0px 2.5px 41.66667px 0px rgba(0, 0, 0, 0.10)',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            padding:'20px 15px 15px 15px',
            mt:'41px',
            width:'287px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'0px',
                width:'calc(100% - 36px)',
                padding:'40px 18px 18px 18px',
            },
        }}> 
            <Typography sx={{
                color:type === 1 ? '#FFFFFF' : '#4AB3FF',
                fontSize:'25px',
                fontWeight:600,
                textAlign:'center',
            }}>
                {title}
            </Typography>
            <Typography sx={{
                color:type === 1 ? '#FFFFFF' : '#7A7A7A',
                fontSize:'13px',
                fontWeight:500,
                textAlign:'center',
                maxWidth:'200px',
                mt:'7px',
            }}>
                {desc}
            </Typography>
            <Typography sx={{
                color:type === 1 ? '#FFFFFF' : '#4AB3FF',
                fontSize:'30px',
                fontWeight:600,
                textAlign:'center',
                mt:'24px',
            }}>
                {`$${price}`}
            </Typography>
            <Box sx={{
                bgcolor:type === 1 ? '#FFFFFF' : '#F8FBFE',
                borderRadius:'7px',
                mt:type === 1 ? '30px' : '39px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'flex-start',
                padding:'24px 20px 22px 20px',
                gap:'8px',
                width:'calc(100% - 40px)',
            }}>
                {supports.map((item,index) => (
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        padding:'0px 14px',
                        width:'calc(100% - 28px)',
                    }} key={index}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                        <path opacity="0.5" d="M6.91732 11.7084C9.7938 11.7084 12.1257 9.37656 12.1257 6.50008C12.1257 3.6236 9.7938 1.29175 6.91732 1.29175C4.04083 1.29175 1.70898 3.6236 1.70898 6.50008C1.70898 9.37656 4.04083 11.7084 6.91732 11.7084Z" stroke="#4AB3FF" stroke-width="1.25"/>
                        <path d="M5.09473 6.7605L6.13639 7.80216L8.74056 5.198" stroke="#4AB3FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <Typography sx={{
                            color:'#000000',
                            fontSize:'12px',
                            fontWeight:400,
                            ml:'8px',
                        }}>
                            {item}
                        </Typography>
                    </Box>
                ))}
                <Button sx={{
                    textTransform:'none',
                    bgcolor:type === 1 ? '#4AB3FF' : 'transparent',
                    boxShadow:type === 1 ? '0px 2.5px 41.66667px 0px rgba(0, 0, 0, 0.10)' : 'none',
                    borderRadius:'50px',
                    border:type === 1 ? 'none' : '1px solid #4AB3FF',
                    color:type === 1 ? '#FFFFFF' : '#4AB3FF',
                    fontSize:'17px',
                    fontWeight:600,
                    width:'100%',
                    mt:'20px',
                    '&:hover': {
                        bgcolor:type === 1 ? '#4AB3FF' : 'transparent',
                    },
                }} onClick={() => purchaseOnClick(type)}>
                    {btnTitle}
                </Button>
            </Box>
        </Box>
    );
}

const ProductContentMainContent2 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        start_building__aviation: t("start_building__aviation"),
        data_solution: t("data_solution"),
        whether_you__resources: t("whether_you__resources"),
        get_started_now: t("get_started_now"),
        choose_your_plan: t("choose_your_plan"),
        monthly: t("monthly"),
        yearly: t("yearly"),
        standard: t("standard"),
        experiment_the__possibilities: t("experiment_the__possibilities"),
        richer_data__integration: t("richer_data__integration"),
        provide_route__statistics: t("provide_route__statistics"),
        powerful_data__functions: t("powerful_data__functions"),
        provide_data_serve: t("provide_data_serve"),
        advanced_data_forecasting: t("advanced_data_forecasting"),
        advanced_technical_support: t("advanced_technical_support"),
        pay_for_purchase: t("pay_for_purchase"),
        basic: t("basic"),
        have_a__superpowers: t("have_a__superpowers"),
        lower_data__requirements: t("lower_data__requirements"),
        provide_basic__interface: t("provide_basic__interface"),
        data_query__functions: t("data_query__functions"),
        real_data_updates: t("real_data_updates"),
        developer_support__documentation: t("developer_support__documentation"),
        safe_and__interface: t("safe_and__interface"),
        start_free_trial: t("start_free_trial"),
        advanced: t("advanced"),
        highly_personalized: t("highly_personalized"),
        advanced_package_features: t("advanced_package_features"),
        customized_data_integration: t("customized_data_integration"),
        advanced_security__controls: t("advanced_security__controls"),
        large_data__processing: t("large_data__processing"),
        dedicated_technical__team: t("dedicated_technical__team"),
        coming_soon: t("coming_soon"),
    };

    let itemArr = [
        {
            title:textData.basic,
            desc:textData.have_a__superpowers,
            price:'0',  
            supports:[
                textData.large_data__processing,
                textData.provide_basic__interface,
                textData.data_query__functions,
                textData.real_data_updates,
                textData.developer_support__documentation,
                textData.safe_and__interface,
            ],
            type:0,
        },
        {
            title:textData.standard,
            desc:textData.experiment_the__possibilities,
            price:'1999',  
            supports:[
                textData.richer_data__integration,
                textData.provide_route__statistics,
                textData.powerful_data__functions,
                textData.provide_data_serve,
                textData.advanced_data_forecasting,
                textData.advanced_technical_support,
            ],
            type:1,
        },
        {
            title:textData.advanced,
            desc:textData.experiment_the__possibilities,
            price:'2999',  
            supports:[
                textData.highly_personalized,
                textData.advanced_package_features,
                textData.customized_data_integration,
                textData.advanced_security__controls,
                textData.large_data__processing,
                textData.dedicated_technical__team,
            ],
            type:2,
        },
    ];
    if(screenMD) {
        itemArr = [
            {
                title:textData.standard,
                desc:textData.experiment_the__possibilities,
                price:'1999',  
                supports:[
                    textData.richer_data__integration,
                    textData.provide_route__statistics,
                    textData.powerful_data__functions,
                    textData.provide_data_serve,
                    textData.advanced_data_forecasting,
                    textData.advanced_technical_support,
                ],
                type:1,
            },
            {
                title:textData.basic,
                desc:textData.have_a__superpowers,
                price:'0',  
                supports:[
                    textData.large_data__processing,
                    textData.provide_basic__interface,
                    textData.data_query__functions,
                    textData.real_data_updates,
                    textData.developer_support__documentation,
                    textData.safe_and__interface,
                ],
                type:0,
            },
            {
                title:textData.advanced,
                desc:textData.experiment_the__possibilities,
                price:'2999',  
                supports:[
                    textData.highly_personalized,
                    textData.advanced_package_features,
                    textData.customized_data_integration,
                    textData.advanced_security__controls,
                    textData.large_data__processing,
                    textData.dedicated_technical__team,
                ],
                type:2,
            },
        ];
    }

    const [ type,setType ] = useState(0);

    const handleStartClick = () => {

    };
    const handleMenuClick = (event, newValue) => {
        if(newValue !== type) {
            setType(newValue);
        }
    };
    const handlePurchaseClick = (type) => {

    };

    return (
        <Grid sx={{
            background:'linear-gradient(228deg, #C0E4FF 0%, #E3F3FF 29.49%, #FDFEFF 51.79%, #F9FAFA 74.11%, #DEEBE8 93.95%)',
            padding:'0px 88px 68px 88px',
            width:'100%',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                position:'relative',
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'27px',
                    fontWeight:600,
                    whiteSpace:'pre-line',
                    textAlign:'center',
                    mt:'84px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'0px',
                        fontSize:'24px',
                    },
                }}>
                    {`${textData.start_building__aviation}\n`}
                    <span style={{color:'#4AB3FF'}}>{textData.data_solution}</span>
                </Typography>
                <Typography sx={{
                    color:'#7A7A7A',
                    fontSize:'20px',
                    fontWeight:500,
                    lineHeight:'34px',
                    textAlign:'center',
                    mt:'41px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'18px',
                        fontSize:'24px',
                        fontSize:'15px',
                        lineHeight:'28px',
                    },
                }}>
                    {textData.whether_you__resources}
                </Typography>
                <Button sx={{
                    textTransform:'none',
                    bgcolor:'#4AB3FF',
                    borderRadius:'60px',
                    color:'#FFFFFF',
                    fontSize:'13px',
                    fontWeight:600,
                    padding:'6px 46px',
                    mt:'41px',
                    '&:hover': {
                        bgcolor:'#4AB3FF',
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'18px',
                        padding:'0px',
                        width:'100%',
                        height:'36px',
                    },
                }} onClick={handleStartClick}>
                    {textData.get_started_now}
                </Button>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'27px',
                    fontWeight:600,
                    mt:'70px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'48px',
                        fontSize:'24px',
                    },
                }}>
                    {textData.choose_your_plan}
                </Typography>
                <StyledTabs
                    value={type}
                    onChange={handleMenuClick}
                    aria-label="styled tabs example"
                >
                    <StyledTab label={textData.monthly} />
                    <StyledTab label={textData.yearly} />
                </StyledTabs>
                <Box sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'37px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        flexDirection:'column',
                        mt:'48px',
                        gap:'48px',
                        width:'100%',
                    },
                }}>
                    {itemArr.map((item,index) => (
                        <ProductContentMainContent2Item 
                            title={item.title}
                            desc={item.desc}
                            price={item.price}
                            supports={item.supports}
                            type={item.type}
                            btnTitle={item.type === 0 ? textData.start_free_trial : (item.type === 1 ? textData.pay_for_purchase : textData.coming_soon)}
                            purchaseOnClick={handlePurchaseClick}
                            key={index}
                        />
                    ))}
                </Box>
            </Container>
        </Grid>
    );
}

export default ProductContentMainContent2;