import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../component/header/Header';
import ProductContent from '../component/product/ProductContent';
import { useLocation } from 'react-router-dom';

const Product = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const location = useLocation();
    const isBookDemo = location.state && location.state.isBookDemo;

    return (
        <React.Fragment>
            <Header categoryIndex={screenMD ? 1 : 0}/>
            <ProductContent isBookDemo={isBookDemo}/>
        </React.Fragment>
    );
}

export default Product;