import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import image1Icon from '../../assets/images/learn/image1.png';
import image2Icon from '../../assets/images/learn/image2.png';

const LearnContentMainContentLeft = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        data_analysis__insights: t("data_analysis__insights"),
        our_professional__processes: t("our_professional__processes"),
        forecasting_and__analysis: t("forecasting_and__analysis"),
        by_conducting__market: t("by_conducting__market"),
        data_visualization__customization: t("data_visualization__customization"),
        we_believe__maps: t("we_believe__maps"),
        additionally_we__decisions: t("additionally_we__decisions"),
    };
    
    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'flex-start',
            width:'512px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'100%',
            },
        }}>
            <Typography sx={{
                color:'#0F1962',
                fontSize:'32px',
                fontWeight:600,
            }}>
                {textData.data_analysis__insights}
            </Typography>
            <Typography sx={{
                color:'#7A7A7A',
                fontSize:'16px',
                fontWeight:500,
                mt:'12px',
            }}>
                {textData.our_professional__processes}
            </Typography>
            <CardMedia
                component="img"
                image={ image1Icon }
                sx={{
                    width:'100%',
                    height:'196px',
                    mt:'30px',
                    objectFit:'cover',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        height:'calc((100vw - 36px) * 196 / 339)',
                    },
                }}
            />
            <Typography sx={{
                color:'#0F1962',
                fontSize:'32px',
                fontWeight:600,
                mt:'40px',
            }}>
                {textData.forecasting_and__analysis}
            </Typography>
            <Typography sx={{
                color:'#7A7A7A',
                fontSize:'16px',
                fontWeight:500,
                mt:'12px',
            }}>
                {textData.by_conducting__market}
            </Typography>
            <CardMedia
                component="img"
                image={ image2Icon }
                sx={{
                    width:'100%',
                    height:'292px',
                    mt:'30px',
                    objectFit:'cover',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        height:'calc((100vw - 36px) * 292 / 339)',
                    },
                }}
            />
            <Typography sx={{
                color:'#666C89',
                fontSize:'16px',
                fontWeight:500,
                textAlign:'center',
                mt:'12px',
            }}>
                {textData.data_visualization__customization}
            </Typography>
            <Typography sx={{
                color:'#7A7A7A',
                fontSize:'16px',
                fontWeight:500,
                mt:'30px',
            }}>
                {textData.we_believe__maps}
            </Typography>
            <Typography sx={{
                color:'#7A7A7A',
                fontSize:'16px',
                fontWeight:500,
                mt:'30px',
            }}>
                {textData.additionally_we__decisions}
            </Typography>
        </Box>
    );
}

export default LearnContentMainContentLeft;