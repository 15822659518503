import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import image5Icon from '../../assets/images/service/image5.png';

const ServiceContentMainContent4 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        flying_provides_data: t("flying_provides_data"),
        model: t("model"),
        passenger_capacity: t("passenger_capacity"),
        weight: t("weight"),
        voyage: t("voyage"),
        speed: t("speed"),
    };

    const itemArr = [
        {
            title:textData.model,
            desc:'Boeing 747-400',
        },
        {
            title:textData.passenger_capacity,
            desc:'416-524 seats',
        },
        {
            title:textData.weight,
            desc:'396,890 kg',
        },
        {
            title:textData.voyage,
            desc:'13,450 km',
        },
        {
            title:textData.speed,
            desc:'913 km/h',
        },
    ];

    return (
        <Grid sx={{
            bgcolor:'#DFF1FF',
            width:'100%',
            mt:'88px',
            padding:'85px 92px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'28px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1016px',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    flexDirection:'column',
                    alignItems:'flex-start',  
                },
            }}>
                <CardMedia
                    component="img"
                    image={ image5Icon }
                    sx={{
                        width:'543px',
                        height:'323px',
                        objectFit:'cover',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'100%',
                            height:'calc(100% * 191 / 339)',
                        },
                    }}
                />
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',   
                    ml:'56px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        ml:'0px',
                        mt:'28px',
                        width:'100%',
                    },
                }}>
                    <Typography sx={{
                        color:'#0F1962',
                        fontSize:'30px',
                        fontWeight:600,
                    }}>
                        {textData.flying_provides_data}
                    </Typography>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',  
                        mt:'34px',
                        gap:'17px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'100%',
                        },
                    }}>
                        {itemArr.map((item,index) => (
                            <Box container sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'center',  
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    justifyContent:'space-between',
                                    width:'100%',
                                },
                            }} key={index}>
                                <Box container sx={{
                                    display:'flex',
                                    justifyContent:'space-between',
                                    alignItems:'center',  
                                    minWidth:'100px',
                                }} key={index}>
                                    <Typography sx={{
                                        color:'#1C1F35',
                                        fontSize:'17px',
                                        fontWeight:500,
                                    }}>
                                        {item.title}
                                    </Typography>
                                    {screenMD ? '' : 
                                        <Typography sx={{
                                            color:'#1C1F35',
                                            fontSize:'17px',
                                            fontWeight:500,
                                        }}>
                                            {':'}
                                        </Typography>
                                    }
                                </Box>
                                <Typography sx={{
                                    color:'#1C1F35',
                                    fontSize:'17px',
                                    fontWeight:400,
                                    ml:'23px',
                                }}>
                                    {item.desc}
                                </Typography>
                            </Box>
                        ))}
                    </Box>  
                </Box>
            </Container>
        </Grid>
    );
}

export default ServiceContentMainContent4;