import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import imageIcon6 from '../../assets/images/home/home-content-image6.png';

const HomeContentMainContent6 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        professional_team: t("professional_team"),
        capabilities: t("capabilities"),
        though_small__services: t("though_small__services"),
    };

    return (
        <Grid sx={{
            width:'100%',
            mt:'120px',
            padding:'0px 88px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#4AB3FF',
                    fontSize:'24px',
                    fontWeight:700,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'18px',
                    },
                }}>
                    {textData.professional_team}
                </Typography>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'36px',
                    fontWeight:700,
                    textTransform:'uppercase',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'32px',
                    },
                }}>
                    {textData.capabilities}
                </Typography>
                <Box sx={{
                    width:'100%',
                    height:'546px',
                    position:'relative',
                    mt:'28px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'18px',
                        height:'calc((100vw - 36) * 326 / 339)',
                    },
                }}>
                    <CardMedia
                        component="img"
                        image={ imageIcon6 }
                        sx={{
                            position:'absolute',
                            top:'0px',
                            left:'0px',
                            width:'100%',
                            height:'100%',
                            objectFit:'cover',
                            borderRadius:'18px',
                            border:'none',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                borderRadius:'8px',
                            },
                        }}
                    />
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'22px',
                        fontWeight:400,
                        width:'490px',
                        position:'absolute',
                        bottom:'58px',
                        left:'60px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'22px',
                            width:'calc(100% - 36px)',
                            bottom:'28px',
                            left:'18px',
                        },
                    }}>
                        {textData.though_small__services}
                    </Typography>
                </Box>
            </Container>
        </Grid>
    );
}

export default HomeContentMainContent6;