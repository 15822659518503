import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const HomeContentMainContent5 = (props) => {
    const {
        mt,mb,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        what_our__say: t("what_our__say"),
        the_data__friendly: t("the_data__friendly"),
        providing_detailed__updates: t("providing_detailed__updates"),
        their_customer__issues: t("their_customer__issues"),
        we_are__experience: t("we_are__experience"),
        the_aviation__solution: t("the_aviation__solution"),
        their_data__decisions: t("their_data__decisions"),
        and_enhance__efficiency: t("and_enhance__efficiency"),
        we_sincerely__success: t("we_sincerely__success"),
        thank_you__information: t("thank_you__information"),
        helping_us__travels: t("helping_us__travels"),
        their_data__impressive: t("their_data__impressive"),
        we_greatly__needs: t("we_greatly__needs"),
        the_team__responsive: t("the_team__responsive"),
        their_data__operations: t("their_data__operations"),
        and_provided__insights: t("and_provided__insights"),
        we_sincerely__operations: t("we_sincerely__operations"),
        it_was__company: t("it_was__company"),
        their_data__coverage: t("their_data__coverage"),
        have_helped__trends: t("have_helped__trends"),
        we_appreciate__trends: t("we_appreciate__trends"),
    };

    let itemArr = [
        {
            title1:textData.thank_you__information,
            title2:textData.helping_us__travels,
            title3:textData.their_data__impressive,
            desc:textData.we_greatly__needs,
        },
        {
            title1:textData.the_team__responsive,
            title2:textData.their_data__decisions,
            title3:textData.and_enhance__efficiency,
            desc:textData.we_sincerely__operations,
        },
        {
            title1:textData.it_was__company,
            title2:textData.their_data__coverage,
            title3:textData.have_helped__trends,
            desc:textData.we_appreciate__trends,
        },
    ];
    if(screenMD) {
        itemArr = [
            {
                title1:textData.the_data__friendly,
                title2:textData.providing_detailed__updates,
                title3:textData.their_customer__issues,
                desc:textData.we_sincerely__success,
            },
            {
                title1:textData.the_aviation__solution,
                title2:textData.providing_detailed__updates,
                title3:textData.their_customer__issues,
                desc:textData.we_are__experience,
            },
            {
                title1:textData.thank_you__information,
                title2:textData.helping_us__travels,
                title3:textData.their_data__impressive,
                desc:textData.we_greatly__needs,
            },
            {
                title1:textData.the_team__responsive,
                title2:textData.their_data__operations,
                title3:textData.and_provided__insights,
                desc:textData.we_sincerely__operations,
            },
            {
                title1:textData.it_was__company,
                title2:textData.their_data__coverage,
                title3:textData.have_helped__trends,
                desc:textData.we_appreciate__trends,
            },
        ];
    }

    return (
        <Grid sx={{
            width:'100%',
            padding:'0px 88px',
            mt:mt !== undefined ? mt : '120px',
            mb:mb !== undefined ? mb : '0px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:mt !== undefined ? mt : '48px',
                mb:mb !== undefined ? mb : '0px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'36px',
                    fontWeight:700,
                    textTransform:'uppercase',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'32px',
                    },
                }}>
                    {textData.what_our__say}
                </Typography>
                {screenMD ? 
                    <Grid container sx={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        mt:'28px',
                        gap:'28px',
                    }}>
                        {itemArr.map((item,index) => (
                            <Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                width:'100%',
                                borderRadius:'18px',
                                border:'1px solid #000000',
                                padding:'28px',
                            }} key={index}>
                                <Typography sx={{
                                    color:'#0F1962',
                                    fontSize:'15px',
                                    fontWeight:700,
                                    lineHeight:'28px',
                                }}>
                                    {item.title1}
                                    <span style={{color:'#4AB3FF'}}>{item.title2}</span>
                                    {item.title3}
                                </Typography>
                                <Typography sx={{
                                    color:'#7C809F',
                                    fontSize:'10px',
                                    fontWeight:400,
                                    lineHeight:'28px',
                                    mt:'18px'
                                }}>
                                    {item.desc}
                                </Typography>
                            </Box>
                        ))}
                    </Grid> : 
                    <React.Fragment>
                        <Box sx={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            mt:'28px',
                        }}>
                            <Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'start',
                                width:'480px',
                                borderRadius:'18px',
                                border:'1px solid #000000',
                                padding:'48px 28px',
                                height:'100%',
                            }}>
                                <Typography sx={{
                                    color:'#0F1962',
                                    fontSize:'20px',
                                    fontWeight:700,
                                    lineHeight:'38px',
                                }}>
                                    {textData.the_data__friendly}
                                    <span style={{color:'#4AB3FF'}}>{textData.providing_detailed__updates}</span>
                                    {textData.their_customer__issues}
                                </Typography>
                                <Typography sx={{
                                    color:'#7C809F',
                                    fontSize:'12px',
                                    fontWeight:400,
                                    lineHeight:'22px',
                                    mt:'18px'
                                }}>
                                    {textData.we_are__experience}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                width:'480px',
                                borderRadius:'18px',
                                border:'1px solid #000000',
                                padding:'48px 28px',
                                ml:'28px',
                                height:'100%',
                            }}>
                                <Typography sx={{
                                    color:'#0F1962',
                                    fontSize:'20px',
                                    fontWeight:700,
                                    lineHeight:'38px',
                                }}>
                                    {textData.the_aviation__solution}
                                    <span style={{color:'#4AB3FF'}}>{textData.their_data__decisions}</span>
                                    {textData.and_enhance__efficiency}
                                </Typography>
                                <Typography sx={{
                                    color:'#7C809F',
                                    fontSize:'12px',
                                    fontWeight:400,
                                    lineHeight:'22px',
                                    mt:'18px'
                                }}>
                                    {textData.we_sincerely__success}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            mt:'28px',
                        }}>
                            {itemArr.map((item,index) => (
                                <Box sx={{
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'flex-start',
                                    alignItems:'flex-start',
                                    width:'320px',
                                    borderRadius:'18px',
                                    border:'1px solid #000000',
                                    padding:'48px 28px',
                                    ml:index !== 0 ? '28px' : '0px',
                                }} key={index}>
                                    <Typography sx={{
                                        color:'#0F1962',
                                        fontSize:'20px',
                                        fontWeight:700,
                                        lineHeight:'38px',
                                    }}>
                                        {item.title1}
                                        <span style={{color:'#4AB3FF'}}>{item.title2}</span>
                                        {item.title3}
                                    </Typography>
                                    <Typography sx={{
                                        color:'#7C809F',
                                        fontSize:'12px',
                                        fontWeight:400,
                                        lineHeight:'22px',
                                        mt:'18px'
                                    }}>
                                        {item.desc}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </React.Fragment>
                }
            </Container>
        </Grid>
    );
}

export default HomeContentMainContent5;