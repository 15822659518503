import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import image1Icon from '../../assets/images/service/image1.png';
import image2Icon from '../../assets/images/service/image2.png';
import image3Icon from '../../assets/images/service/image3.png';

const ServiceContentMainContent1 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        flight_data_management: t("flight_data_management"),
        through_our__airlines: t("through_our__airlines"),
        by_providing__instantly: t("by_providing__instantly"),
        we_provide__optimization: t("we_provide__optimization"),
    };

    const itemArr = [
        {
            image:image1Icon,
            title:textData.through_our__airlines,
        },
        {
            image:image2Icon,
            title:textData.by_providing__instantly,
        },
        {
            image:image3Icon,
            title:textData.we_provide__optimization,
        },
    ];

    return (
        <Grid sx={{
            width:'100%',
            mt:'88px',
            padding:'0px 88px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1024px',
                }
            }}>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'36px',
                    fontWeight:600,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'32px',
                    },
                }}>
                    {textData.flight_data_management}
                </Typography>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',  
                    mt:'18px', 
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        flexDirection:'column',
                    },
                }}>
                    {itemArr.map((item,index) => (
                        <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',   
                            ml:index === 0 ? '0px' : '28px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                ml:'0px',
                                mt:index === 0 ? '0px' : '28px',
                            },
                        }} key={index}>
                            <CardMedia
                                component="img"
                                image={ item.image }
                                sx={{
                                    width:'323px',
                                    height:'293px',
                                    objectFit:'cover',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        width:'100%',
                                        height:'calc(100% * 293 / 339)',
                                    },
                                }}
                            />
                            <Typography sx={{
                                color:'#666C89',
                                fontSize:'18px',
                                fontWeight:700,
                                mt:'10px',
                            }}>
                                {item.title}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Container>
        </Grid>
    );
}

export default ServiceContentMainContent1;