import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import bannerIcon from '../../assets/images/service/header-banner.png';
import bannerMobileIcon from '../../assets/images/service/header-banner-mobile.png';

const ServiceContentHeader = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        our_core_services: t("our_core_services"),
        intelligent_aviation__industry: t("intelligent_aviation__industry"),
    };

    return (
        <Grid sx={{
            width:'100%',
            position:'relative',
            display:'flex',
            justifyContent:'center',
            alignItems:'flex-start',
            minHeight:'484px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                minHeight:'500px',
            },
        }}>
            <CardMedia
                component="img"
                image={ screenMD ? bannerMobileIcon : bannerIcon }
                sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    objectFit:'cover',
                }}
            />

            <Grid container sx={{
                padding:'90px 88px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'48px 18px 150px 18px',
                },
            }}>
                <Container sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'start',
                    alignItems:'start',
                    position:'relative',
                    '@media': {
                        padding:'0px',
                        maxWidth:'1000px',
                    }
                }}>
                    <Box sx={{
                        bgcolor:'#0A1880',
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                    }}>
                        <Box sx={{
                            bgcolor:'#4AB3FF',
                            width:'3px',
                            height:'100%',
                        }} />
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'26px',
                            fontWeight:400,
                            margin:'2px 9px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'20px',
                                margin:'6px 9px',
                            },
                        }}>
                            {textData.our_core_services}
                        </Typography>
                    </Box>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'48px',
                        fontWeight:700,
                        maxWidth:'940px',
                        lineHeight:'50px',
                        mt:'28px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'32px',
                            maxWidth:'100%',
                            lineHeight:'35px',
                        },
                    }}>
                        {textData.intelligent_aviation__industry}
                    </Typography>
                </Container>
            </Grid>
        </Grid>
    );
}

export default ServiceContentHeader;