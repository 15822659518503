import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import imageIcon1 from '../../assets/images/home/home-content-image1.png';
import imageIcon2 from '../../assets/images/home/home-content-image2.png';

const HomeContentMainContent2 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        why_choose_soft: t("why_choose_soft"),
        global_reach__insight: t("global_reach__insight"),
        we_offer__market: t("we_offer__market"),
        reliability_and_accuracy: t("reliability_and_accuracy"),
        our_data__confidently: t("our_data__confidently"),
        customer_centric_approach: t("customer_centric_approach"),
        we_prioritize__customization: t("we_prioritize__customization"),
        companies_prefer__tickets: t("companies_prefer__tickets"),
        customer_centric__you: t("customer_centric__you"),
    };

    return (
        <Grid sx={{
            width:'100%',
            mt:'130px',
            padding:'0px 128px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    mb:'100px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'32px',
                        width:'100%',
                        mb:'0px',
                    },
                }}>
                    <Typography sx={{
                        color:'#0F1962',
                        fontSize:'36px',
                        fontStyle:'normal',
                        fontWeight:700,
                        textTransform:'uppercase',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'32px',
                        },
                    }}>
                        {textData.why_choose_soft}
                    </Typography>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        mt:'28px',
                        width:'100%',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            mt:'18px',
                            flexDirection:'column',
                        },
                    }}>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                        }}>
                            <Box sx={{
                                position:'relative',
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                padding:'48px 28px',
                                height:'310px',
                                width:'240px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    padding:'28px 16px',
                                    height:'218px',
                                    width:'calc((100vw - 100px) / 2)',
                                },
                            }}>
                                <CardMedia
                                    component="img"
                                    image={ imageIcon1 }
                                    sx={{
                                        position:'absolute',
                                        top:'0px',
                                        left:'0px',
                                        width:'100%',
                                        height:'100%',
                                        objectFit:'cover',
                                        border:'none',
                                    }}
                                />
                                <Typography sx={{
                                    color:'#FFFFFF',
                                    fontSize:'24px',
                                    fontStyle:'normal',
                                    fontWeight:700,
                                    zIndex:1,
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        fontSize:'15px',
                                    },
                                }}>
                                    {textData.global_reach__insight}
                                </Typography>
                                <Typography sx={{
                                    color:'#D8E3FF',
                                    fontSize:'14px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                    mt:'18px',
                                    zIndex:1,
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        fontSize:'10px',
                                        mt:'10px',
                                    },
                                }}>
                                    {textData.we_offer__market}
                                </Typography>
                            </Box>
                            <Box sx={{
                                position:'relative',
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                padding:'48px 28px',
                                height:'310px',
                                width:'240px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    padding:'28px 16px',
                                    height:'218px',
                                    width:'calc((100vw - 100px) / 2)',
                                },
                            }}>
                                <CardMedia
                                    component="img"
                                    image={ imageIcon2 }
                                    sx={{
                                        position:'absolute',
                                        top:'0px',
                                        left:'0px',
                                        width:'100%',
                                        height:'100%',
                                        objectFit:'cover',
                                        border:'none'
                                    }}
                                />
                                <Typography sx={{
                                    color:'#FFFFFF',
                                    fontSize:'24px',
                                    fontStyle:'normal',
                                    fontWeight:700,
                                    zIndex:1,
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        fontSize:'15px',
                                    },
                                }}>
                                    {textData.reliability_and_accuracy}
                                </Typography>
                                <Typography sx={{
                                    color:'#D8E3FF',
                                    fontSize:'14px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                    mt:'18px',
                                    zIndex:1,
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        fontSize:'10px',
                                        mt:'10px',
                                    },
                                }}>
                                    {textData.our_data__confidently}
                                </Typography>
                            </Box>
                        </Box>
                        
                        <Box sx={{
                            backgroundColor:'#4396D2',
                            position:'relative',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            padding:'48px 28px',
                            height:'310px',
                            width:'360px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'calc(100% - 56px)',
                                height:'auto',
                            },
                        }}>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'24px',
                                fontStyle:'normal',
                                fontWeight:700,
                                zIndex:1,
                            }}>
                                {textData.customer_centric_approach}
                            </Typography>
                            <Typography sx={{
                                color:'#D8E3FF',
                                fontSize:'14px',
                                fontStyle:'normal',
                                fontWeight:400,
                                mt:'18px',
                                zIndex:1,
                            }}>
                                {textData.we_prioritize__customization}
                            </Typography>
                            <Box sx={{
                                mt:'67px',
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                zIndex:1,
                            }}>
                                <Typography sx={{
                                    color:'#FFFFFF',
                                    fontSize:'96px',
                                    fontStyle:'normal',
                                    fontWeight:700,
                                    lineHeight:'40px',
                                    mt:'20px',
                                }}>
                                    {'567'}
                                </Typography>
                                <Typography sx={{
                                    color:'#FFFFFF',
                                    fontSize:'20px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                    ml:'18px',
                                }}>
                                    {textData.companies_prefer__tickets}
                                </Typography>
                            </Box>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'15px',
                                fontStyle:'normal',
                                fontWeight:400,
                                mt:'59px',
                                zIndex:1,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    color:'#D8E3FF',
                                    fontSize:'14px',
                                },
                            }}>
                                {textData.customer_centric__you}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Grid>
    );
}

export default HomeContentMainContent2;