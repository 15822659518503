import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const LearnContentMainContentRight = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        dashboard: t("dashboard"),
        partner_company: t("partner_company"),
        service_provider: t("service_provider"),
        data_center: t("data_center"),
        active_user: t("active_user"),
        total_likes: t("total_likes"),
        how_can__you: t("how_can__you"),
        our_professional__needs: t("our_professional__needs"),
        contact: t("contact"),
        get_in_touch: t("get_in_touch"),
        need_help: t("need_help"),
        email: t("email"),
    };

    const itemArr = [
        {
            title:textData.partner_company,
            amount:186,
        },
        {
            title:textData.service_provider,
            amount:12315,
        },
        {
            title:textData.data_center,
            amount:8672,
        },
        {
            title:textData.active_user,
            amount:8588,
        },
        {
            title:textData.total_likes,
            amount:86886,
        },
    ];

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'flex-start',
            width:'440px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'100%',
            },
        }}>
            <Box sx={{
                bgcolor:'#DFF1FF',
                border:'1px solid #EEEEEE',
                padding:'46px 42px',
                width:'calc(100% - 84px)',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'28px 18px',
                    width:'calc(100% - 36px)',
                },
            }}>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'22px',
                    fontWeight:600,
                }}>
                    {textData.dashboard}
                </Typography>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    mt:'30px',
                    width:'100%',
                    gap:'19px',
                }}>
                    {itemArr.map((item,index) => (
                        <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            width:'100%',
                        }} key={index}>
                            <Grid container sx={{
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'flex-start',
                                gap:'5px',
                                width:'100%',
                            }}>
                                <Typography sx={{
                                    color:'#7A7A7A',
                                    fontSize:'18px',
                                    fontWeight:400,
                                }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{
                                    color:'#1C1F35',
                                    fontSize:'16px',
                                    fontWeight:500,
                                }}>
                                    {`(${item.amount})`}
                                </Typography>
                            </Grid>
                            <Box sx={{
                                bgcolor:'#AAC0D0',
                                mt:'15px',
                                width:'100%',
                                height:'1px',
                            }} />
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box sx={{
                bgcolor:'#DFF1FF',
                border:'1px solid #EEEEEE',
                padding:'46px 42px',
                width:'calc(100% - 84px)',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                mt:'48px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'28px 18px',
                    width:'calc(100% - 36px)',
                },
            }}>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'22px',
                    fontWeight:600,
                }}>
                    {textData.how_can__you}
                </Typography>
                <Typography sx={{
                    color:'#7A7A7A',
                    fontSize:'16px',
                    fontWeight:400,
                    mt:'12px',
                }}>
                    {textData.our_professional__needs}
                </Typography>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems:'flex-start',
                    width:'100%',
                    mt:'30px',
                }}>
                    <Button sx={{
                        textTransform:'none',
                        bgcolor:'#4AB3FF',
                        borderRadius:'44px',
                        color:'#FFFFFF',
                        fontSize:'15px',
                        fontWeight:700,
                        padding:'12px 54px',
                        '&:hover': {
                            bgcolor:'#4AB3FF',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'100%',
                        },
                    }}>
                        {textData.contact}
                    </Button>
                </Box>
            </Box>
            <Box sx={{
                bgcolor:'#0F1962',
                padding:'34px 43px',
                width:'calc(100% - 86px)',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                mt:'48px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'35px 18px',
                    width:'calc(100% - 36px)',
                },
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    minHeight:'23px',
                }}>
                    <Box sx={{
                        bgcolor:'#4AB3FF',
                        width:'4px',
                        height:'100%',
                        minHeight:'23px',
                    }} />
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'22px',
                        fontWeight:600,
                        ml:'10px',
                    }}>
                        {textData.get_in_touch}
                    </Typography>
                </Box>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'18px',
                    fontWeight:400,
                    whiteSpace:'pre-line',
                    mt:'24px',
                }}>
                    {`${textData.need_help}\n(00) 112 365 489`}
                </Typography>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'18px',
                    fontWeight:400,
                    whiteSpace:'pre-line',
                    mt:'48px',
                }}>
                    {`${textData.email}\ncontact@logistics.com`}
                </Typography>
            </Box>
        </Box>
    );
}

export default LearnContentMainContentRight;