import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import imageIcon8 from '../../assets/images/home/home-content-image8.png';
import imageIcon9 from '../../assets/images/home/home-content-image9.png';
import imageIcon10 from '../../assets/images/home/home-content-image10.png';
import imageIcon11 from '../../assets/images/home/home-content-image11.png';
import imageIcon15 from '../../assets/images/home/home-content-image15.png';
import imageIcon16 from '../../assets/images/home/home-content-image16.png';
import imageIcon17 from '../../assets/images/home/home-content-image17.png';
import imageIcon18 from '../../assets/images/home/home-content-image18.png';

const HomeContentMainContent7Item = (props) => {
    const {
        image,title,desc,
    } = props;
    const theme = useTheme();

    const imageArr = [
        imageIcon9,imageIcon10,imageIcon11,
    ];

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'100%',
            },
        }}>
            <CardMedia
                component="img"
                image={ image }
                sx={{
                    width:'230px',
                    height:'198px',
                    objectFit:'cover',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'170px',
                        height:'146px',
                    },
                }}
            />
            <Box sx={{
                bgcolor:'#F3F9FF',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:'174px',
                padding:'50px 28px 0px 28px',
                height:'148px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    alignContent:'center',
                    justifyContent:'center',
                    width:'calc(100% - 212px)',
                    padding:'0px 21px',
                    height:'146px',
                },
            }}>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'20px',
                    fontWeight:400,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }}>
                    {title}
                </Typography>
                <Typography sx={{
                    color:'#000000',
                    fontSize:'12px',
                    fontWeight:400,
                    textTransform:'uppercase',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'9px',
                    },
                }}>
                    {desc}
                </Typography>
                <Box sx={{
                    mt:'28px',
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                }}>
                    {imageArr.map((item,index) => (
                        <CardMedia
                            component="img"
                            image={ item }
                            sx={{
                                width:'14px',
                                height:'14px',
                                objectFit:'contain',
                                ml:index === 0 ? '0px' : '18px',
                            }}
                            key={index}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

const HomeContentMainContent7 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const itemArr1 = [
        {
            image:imageIcon15,
            title:'John Anderson',
            desc:'ceo / architect',
        },
        {
            image:imageIcon16,
            title:'David Thompson',
            desc:'cto',
        },
    ];
    const itemArr2 = [
        {
            image:imageIcon17,
            title:'Andrew Mitchell',
            desc:'cmo',
        },
        {
            image:imageIcon18,
            title:'Robert Davis',
            desc:'coo',
        },
    ];
    const itemArrMobile = [
        {
            image:imageIcon15,
            title:'John Anderson',
            desc:'ceo / architect',
        },
        {
            image:imageIcon16,
            title:'David Thompson',
            desc:'cto',
        },
        {
            image:imageIcon17,
            title:'Andrew Mitchell',
            desc:'cmo',
        },
        {
            image:imageIcon18,
            title:'Robert Davis',
            desc:'coo',
        },
    ];

    if(screenMD) {
        return (
            <Box sx={{
                width:'calc(100% - 36px)',
                mt:'48px',
                padding:'0px 18px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'8px',
            }}>
                {itemArrMobile.map((item,index) => (
                    <HomeContentMainContent7Item 
                        image={item.image}
                        title={item.title}
                        desc={item.desc}
                        key={index} 
                    />
                ))}
                <Box sx={{
                    bgcolor:'#B6DBFF',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'100%',
                    height:'calc((100vw - 36px) * 146 / 339)',
                }}>
                    <CardMedia
                        component="img"
                        image={ imageIcon8 }
                        sx={{
                            width:'92px',
                            height:'92px',
                            objectFit:'contain',
                        }}
                    />
                </Box>
            </Box>
        );
    }

    return (
        <Grid sx={{
            width:'100%',
            mt:'120px',
            padding:'0px 26px',
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignContent:'center',
                alignItems:'center',               
                '@media': {
                    padding:'0px',
                    maxWidth:'1144px',
                }
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                }}>
                    {itemArr1.map((item,index) => (
                        <HomeContentMainContent7Item 
                            image={item.image}
                            title={item.title}
                            desc={item.desc}
                            key={index} 
                        />
                    ))}
                    <Box sx={{
                        bgcolor:'#B6DBFF',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        width:'230px',
                        height:'198px',
                    }}>
                        <CardMedia
                            component="img"
                            image={ imageIcon8 }
                            sx={{
                                width:'125px',
                                height:'125px',
                                objectFit:'contain',
                            }}
                        />
                    </Box>
                </Box>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                }}>
                    {itemArr2.map((item,index) => (
                        <HomeContentMainContent7Item 
                            image={item.image}
                            title={item.title}
                            desc={item.desc}
                            key={index} 
                        />
                    ))}
                </Box>
            </Container>
        </Grid>
    );
}

export default HomeContentMainContent7;