import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const ApiContentMainContent1 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        our_core__excellence: t("our_core__excellence"),
        our_product__requirements: t("our_product__requirements"),
    };

    return (
        <Grid sx={{
            width:'100%',
            mt:'50px',
            padding:'0px 88px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'0px',
                padding:'12px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'36px',
                    fontWeight:600,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'32px',
                    },
                }}>
                    {textData.our_core__excellence}
                </Typography>
                <Typography sx={{
                    color:'#666C89',
                    fontSize:'20px',
                    fontWeight:500,
                    mt:'18px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }}>
                    {textData.our_product__requirements}
                </Typography>
            </Container>
        </Grid>
    );
}

export default ApiContentMainContent1;