import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import image4Icon from '../../assets/images/service/image4.png';

const ServiceContentMainContent2 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        experts_in__intelligence: t("experts_in__intelligence"),
        as_experts__together: t("as_experts__together"),
    };

    return (
        <Grid sx={{
            width:'100%',
            mt:'88px',
            padding:'0px 88px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1024px',
                }
            }}>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'36px',
                    fontWeight:600,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'32px',
                    },
                }}>
                    {textData.experts_in__intelligence}
                </Typography>
                <Typography sx={{
                    color:'#666C89',
                    fontSize:'15px',
                    fontWeight:500,
                    mt:'18px',
                }}>
                    {textData.as_experts__together}
                </Typography>
                <CardMedia
                    component="img"
                    image={ image4Icon }
                    sx={{
                        width:'1024px',
                        height:'354px',
                        mt:'18px',
                        objectFit:'cover',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'calc(100vw - 36px)',
                            height:'calc((100vw - 36px) * 354 / 339)',
                        },
                    }}
                />
            </Container>
        </Grid>
    );
}

export default ServiceContentMainContent2;