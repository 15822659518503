import React from 'react';
import HomeContentMain from './HomeContentMain';
import Footer from '../footer/Footer';
import HomeContentHeader from './HomeContentHeader';

const HomeContent = () => {

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <React.Fragment>
            <HomeContentHeader />
            <HomeContentMain />
            <Footer />
        </React.Fragment>
    );
}

export default HomeContent;