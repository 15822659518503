import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeContent from '../component/home/HomeContent';
import Header from '../component/header/Header';

const Home = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    if(screenMD) {
        return (
            <React.Fragment>
                <Header categoryIndex={0}/>
                <HomeContent />
            </React.Fragment>
        )
    }

    return (
        <HomeContent />
    )
}

export default Home;