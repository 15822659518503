import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import image1Icon from '../../assets/images/api/image1.png';
import image2Icon from '../../assets/images/api/image2.png';

const ApiContentMainContent3 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        data_processing: t("data_processing"),
        data_visualization: t("data_visualization"),
        the_expert__and: t("the_expert__and"),
        decision_support: t("decision_support"),
        operation_data: t("operation_data"),
        by_analyzing__a: t("by_analyzing__a"),
        targeted_manner: t("targeted_manner"),
        travel_agency: t("travel_agency"),
        horizon_travels: t("horizon_travels"),
        horizon_travels__covering: t("horizon_travels__covering"),
        various_destinations: t("various_destinations"),
        lufthansa_systems: t("lufthansa_systems"),
        wanderlust_adventures__immersive: t("wanderlust_adventures__immersive"),
        travel_experiences: t("travel_experiences"),
    };

    const leftItemArr = [
        {
            title:textData.data_visualization,
            desc1:textData.the_expert__and,
            desc2:textData.decision_support,
        },
        {
            title:textData.operation_data,
            desc1:textData.by_analyzing__a,
            desc2:textData.targeted_manner,
        },
    ];
    const rightItemArr = [
        {
            title:textData.horizon_travels,
            desc1:textData.horizon_travels__covering,
            desc2:textData.various_destinations,
            image:image1Icon,
        },
        {
            title:textData.lufthansa_systems,
            desc1:textData.wanderlust_adventures__immersive,
            desc2:textData.travel_experiences,
            image:image2Icon,
        },
    ];

    return (
        <Grid sx={{
            width:'100%',
            margin:'88px 0px 88px 0px',
            padding:'0px 88px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                margin:'48px 0px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    flexDirection:'column',
                },
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'468px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                    },
                }}>
                    <Typography sx={{
                        color:'#0F1962',
                        fontSize:'40px',
                        fontWeight:700,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'32px',
                        },
                    }}>
                        {textData.data_processing}
                    </Typography>
                    {leftItemArr.map((item,index) => (
                        <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            mt:'30px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                mt:'28px',
                            },
                        }} key={index}>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'center',
                            }}>
                                <Box sx={{
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    minWidth:'48px',
                                    minHeight:'48px',
                                    bgcolor:'#4AB3FF',
                                    borderRadius:'8px',
                                }}>
                                    {index === 0 ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M13.9164 32C6.24107 32 0 25.779 0 18.1284C0 10.4778 6.24107 4.25679 13.9164 4.25679C14.5431 4.25679 15.0535 4.76555 15.0535 5.39022C15.0535 6.01489 14.5431 6.52365 13.9164 6.52365C7.50091 6.53009 2.28064 11.7335 2.28064 18.1284C2.28064 24.5232 7.50091 29.7267 13.9164 29.7267C20.3319 29.7267 25.5522 24.5232 25.5522 18.1284C25.5522 17.5037 26.0626 16.995 26.6893 16.995C27.316 16.995 27.8328 17.5037 27.8328 18.1284C27.8328 25.779 21.5918 32 13.9164 32Z" fill="white"/>
                                        <path d="M30.8565 14.9986C30.2298 14.9986 29.7194 14.4898 29.7194 13.8652C29.7194 7.47032 24.4991 2.26685 18.0836 2.26685C17.4569 2.26685 16.9465 1.7581 16.9465 1.13343C16.9465 0.508754 17.4569 0 18.0836 0C25.7589 0 32 6.22097 32 13.8716C32 14.4963 31.4896 15.005 30.8629 15.005L30.8565 14.9986Z" fill="white"/>
                                        <path d="M13.9164 19.2618C13.2897 19.2618 12.7729 18.7531 12.7729 18.1284V5.39022C12.7729 4.76555 13.2833 4.25679 13.9164 4.25679C14.5496 4.25679 15.0535 4.76555 15.0535 5.39022V18.1284C15.0535 18.7595 14.5431 19.2683 13.9164 19.2683V19.2618Z" fill="white"/>
                                        <path d="M13.9164 19.2618C13.2897 19.2618 12.7729 18.7531 12.7729 18.1284C12.7729 17.5037 13.2833 16.995 13.9164 16.995H26.6957C27.3224 16.995 27.8393 17.5037 27.8393 18.1284C27.8393 18.7531 27.3289 19.2618 26.6957 19.2618H13.9164Z" fill="white"/>
                                        <path d="M18.0771 15.1854C17.4504 15.1854 16.94 14.6766 16.94 14.0519V1.31375C16.94 0.689072 17.4504 0.180318 18.0771 0.180318C18.7038 0.180318 19.2207 0.689072 19.2207 1.31375V14.0519C19.2207 14.6766 18.7103 15.1854 18.0771 15.1854Z" fill="white"/>
                                        <path d="M18.0771 15.1854C17.4504 15.1854 16.94 14.6766 16.94 14.0519C16.94 13.4273 17.4504 12.9185 18.0771 12.9185H30.8565C31.4831 12.9185 31.9935 13.4272 31.9935 14.0519C31.9935 14.6766 31.4831 15.1853 30.8565 15.1853L18.0771 15.1854Z" fill="white"/>
                                    </svg> :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none">
                                        <path d="M28.3884 7.99649C28.0824 7.99649 27.7951 8.11516 27.5827 8.33377C27.3703 8.54614 27.2454 8.8397 27.2454 9.13951V26.6159C27.2454 27.4404 26.9206 28.2274 26.3397 28.8083C25.7588 29.3892 24.9718 29.714 24.1474 29.714H5.85273C5.02825 29.714 4.23501 29.3892 3.65413 28.8083C3.067 28.2211 2.74221 27.4466 2.74221 26.6159V9.13951C2.74221 7.24072 4.27873 5.71044 6.17752 5.71044H23.3604L21.8613 7.22198C21.4179 7.67169 21.4179 8.39623 21.8676 8.8397C22.3173 9.28317 23.0418 9.28317 23.4853 8.83346L26.9144 5.37315C27.3578 4.92344 27.3578 4.20514 26.9144 3.76168L23.4853 0.332601C23.0356 -0.110867 22.3173 -0.110867 21.8676 0.332601C21.4241 0.782316 21.4241 1.50061 21.8676 1.95032L23.3479 3.43064H6.18377C4.66598 3.43064 3.21066 4.03025 2.13634 5.10457C1.06202 6.1664 0.462402 7.62172 0.462402 9.13951V26.6159C0.462402 28.0463 1.03079 29.4204 2.04265 30.426C3.05451 31.4379 4.42864 32 5.85898 32H24.1536C25.584 32 26.9518 31.4379 27.9637 30.426C28.9756 29.4141 29.5377 28.0463 29.5377 26.6159V9.13951C29.5377 8.83346 29.419 8.54614 29.2004 8.33377C28.988 8.12141 28.6945 7.99649 28.3947 7.99649H28.3884Z" fill="white"/>
                                        <path d="M6.99578 20.7072V25.2793C6.99578 25.6853 7.21439 26.0663 7.56417 26.2661C7.92019 26.4723 8.35117 26.4723 8.70719 26.2661C9.06322 26.06 9.27558 25.6853 9.27558 25.2793V20.7072C9.27558 20.3012 9.05697 19.9202 8.70719 19.7203C8.35117 19.5142 7.92019 19.5142 7.56417 19.7203C7.20815 19.9264 6.99578 20.3012 6.99578 20.7072Z" fill="white"/>
                                        <path d="M13.8602 11.5567V25.2793C13.8602 25.6853 14.0788 26.0663 14.4286 26.2661C14.7846 26.4723 15.2156 26.4723 15.5716 26.2661C15.9276 26.06 16.14 25.6853 16.14 25.2793V11.5567C16.14 11.1507 15.9214 10.7697 15.5716 10.5699C15.2156 10.3637 14.7846 10.3637 14.4286 10.5699C14.0725 10.776 13.8602 11.1507 13.8602 11.5567Z" fill="white"/>
                                        <path d="M20.7183 18.4211V25.2855C20.7183 25.6915 20.9369 26.0725 21.2867 26.2724C21.6427 26.4785 22.0737 26.4785 22.4297 26.2724C22.7858 26.0663 22.9981 25.6915 22.9981 25.2855V18.4274C22.9981 18.0214 22.7795 17.6404 22.4297 17.4405C22.0737 17.2344 21.6427 17.2344 21.2867 17.4405C20.9307 17.6466 20.7183 18.0214 20.7183 18.4274V18.4211Z" fill="white"/>
                                    </svg>
                                    }
                                </Box>
                                <Typography sx={{
                                    color:'#000000',
                                    fontSize:'20px',
                                    fontWeight:600,
                                    lineHeight:'24px',
                                    textTransform:'uppercase',
                                    ml:'18px',
                                }}>
                                    {item.title}
                                </Typography>
                            </Box>
                            <Typography sx={{
                                color:'#646E79',
                                fontSize:'16px',
                                fontWeight:400,
                                lineHeight:'24px',
                                mt:'18px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    mt:'8px',
                                },
                            }}>
                                {item.desc1}
                                <span style={{color:'#000000',fontWeight:600}}>{item.desc2}</span>
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'468px',
                    ml:'88px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        margin:'28px 0px 0px 0px',
                        width:'100%',
                    },
                }}>
                    <Typography sx={{
                        color:'#0F1962',
                        fontSize:'40px',
                        fontWeight:700,
                    }}>
                        {textData.travel_agency}
                    </Typography>
                    {rightItemArr.map((item,index) => (
                        <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            mt:'30px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                mt:'28px',
                            },
                        }} key={index}>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'center',
                            }}>
                                 <CardMedia
                                    component="img"
                                    image={ item.image }
                                    sx={{
                                        width:'48px',
                                        height:'48px',
                                        borderRadius:'8px',
                                        objectFit:'contain',
                                    }}
                                />
                                <Typography sx={{
                                    color:'#000000',
                                    fontSize:'20px',
                                    fontWeight:600,
                                    lineHeight:'24px',
                                    ml:'18px',
                                }}>
                                    {item.title}
                                </Typography>
                            </Box>
                            <Typography sx={{
                                color:'#646E79',
                                fontSize:'16px',
                                fontWeight:400,
                                lineHeight:'24px',
                                mt:'18px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    mt:'8px',
                                },
                            }}>
                                {item.desc1}
                                <span style={{color:'#000000',fontWeight:600}}>{item.desc2}</span>
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Container>
        </Grid>
    );
}

export default ApiContentMainContent3;