import React from 'react';
import Grid from '@mui/material/Grid';
import HomeContentMainContent1 from './HomeContentMainContent1';
import HomeContentMainContent2 from './HomeContentMainContent2';
import HomeContentMainContent3 from './HomeContentMainContent3';
import HomeContentMainContent4 from './HomeContentMainContent4';
import HomeContentMainContent5 from './HomeContentMainContent5';
import HomeContentMainContent6 from './HomeContentMainContent6';
import HomeContentMainContent7 from './HomeContentMainContent7';
import HomeContentMainContent8 from './HomeContentMainContent8';
import HomeContentMainAnalysis from './HomeContentMainAnalysis';
import HomeContentMainRealtime from './HomeContentMainRealtime';

const HomeContentMain = () => {

    return (
        <Grid sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
        }}>
            <HomeContentMainContent1 />
            <HomeContentMainAnalysis />
            <HomeContentMainContent2 />
            <HomeContentMainRealtime />
            <HomeContentMainContent3 />
            <HomeContentMainContent4 />
            <HomeContentMainContent5 />
            <HomeContentMainContent6 />
            <HomeContentMainContent7 />
            <HomeContentMainContent8 />
        </Grid>
    )
}

export default HomeContentMain;