import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu, { menuClasses }from '@mui/material/Menu';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { paperClasses } from '@mui/material/Paper';
import { popoverClasses } from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
      borderRadius: 8,
      marginTop: 0,
      color: '#FFFFFF',
      [`& .${menuClasses.list}`]: {
        padding: '0px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        alignItems:'start',
        gap:'5px',
      },
      [`& .${menuItemClasses.root}`]: {
        '&:active': 'transparent',
      },
    },
    [`& .${popoverClasses.paper}`]: {
        backgroundColor: '#FFFFFF',
    }
}));

const HeaderMenu = (props) => {
    const {
        categoryIndex,
    } = props;
    const navigate = useNavigate();
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t,i18n } = useTranslation();
    const textData = {
        home: t("home"),
        product: t("product"),
        api: t("api"),
        services: t("services"),
        learn: t("learn"),
        go_to_shop: t("go_to_shop"),
    };

    const leftMenuTitleArr = [
        textData.product,
        textData.api,
        textData.services,
        textData.learn,
    ];
    const menuTitleArr = [
        textData.home,
        textData.product,
        textData.api,
        textData.services,
        textData.learn,
    ];

    const languageMenuItemArr = [
        {
            language:'en',
            languageText:'English',
        },
        {
            language:'ko',
            languageText:'한국인',
        },
        {
            language:'zh',
            languageText:'繁體中文',
        },
        {
            language:'pt',
            languageText:'Português',
        },
        {
            language:'es',
            languageText:'Español',
        },
    ];

    const chooseLanguageText = () => {
        if(i18n.language.toLowerCase().includes("ko")) {
            return '한국인';
        }else if(i18n.language.toLowerCase().includes("zh")) {
            return '繁體中文';
        }else if(i18n.language.toLowerCase().includes("pt")) {
            return 'Português';
        }else if(i18n.language.toLowerCase().includes("es")) {
            return 'Español';
        }else {
            return 'English';
        }
    }
    const [ languageText, setLanguageText] = useState(chooseLanguageText());

    // language
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeLanguage = (lang) => {
        handleClose();
        if(!i18n.language.toLowerCase().includes(lang)) {
            i18n.changeLanguage(lang);
            if(lang === 'ko') {
                setLanguageText('한국인');
            }else if(lang === 'zh') {
                setLanguageText('繁體中文');
            }else if(lang === 'pt') {
                setLanguageText('Português');
            }else if(lang === 'es') {
                setLanguageText('Español');
            }else {
                setLanguageText('English');
            }
        }
    }
    // menu
    const [ anchorElMenu,setAnchorElMenu ] = useState(null);
    const openMenu = Boolean(anchorElMenu);
    const handleMenuClick = (event) => {
        if(screenMD) {
            setAnchorElMenu(event.currentTarget);
        }
    };
    const handleCloseMenuClick = () => {
        setAnchorElMenu(null);
    };

    const handleHomeClick = () => {
        if(!screenMD) {
            navigate(RoutePath.home);
        }
    };
    const handleLeftMenuClick = (index) => {
        if(screenMD) {
            if(categoryIndex < 0 && index === 0) {
                return;
            }
            if(index === 0) {
                navigate(RoutePath.home);
            }else if(index === 1) {
                navigate(RoutePath.product);
            }else if(index === 2) {
                navigate(RoutePath.api);
            }else if(index === 3) {
                navigate(RoutePath.service);
            }else if(index === 4) {
                navigate(RoutePath.learn);
            }
        }else {
            if(index === categoryIndex) {
                return;
            }
            if(index === 0) {
                navigate(RoutePath.product);
            }else if(index === 1) {
                navigate(RoutePath.api);
            }else if(index === 2) {
                navigate(RoutePath.service);
            }else if(index === 3) {
                navigate(RoutePath.learn);
            }
        }
    };

    return (
        <React.Fragment>
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                gap:'30px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'0px 18px 0px 10px',
                },
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'28px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'2px',
                        cursor:'pointer',
                    },
                }} onClick={handleMenuClick}>
                    <IconButton onClick={handleHomeClick}>
                        {screenMD ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12.0003 0C5.37249 0 0 5.37249 0 12C0 18.6275 5.37249 24 12.0003 24C18.6282 24 24.0003 18.6275 24.0003 12C24.0003 5.37249 18.6275 0 12.0003 0ZM12.0003 22.4999C6.20118 22.4999 1.50008 17.7988 1.50008 12C1.50008 6.20118 6.20118 1.49975 12.0003 1.49975C17.7995 1.49975 22.5003 6.20084 22.5003 12C22.5003 17.7988 17.7992 22.4999 12.0003 22.4999ZM16.2019 7.0589H7.32793C6.91983 7.0589 6.58835 7.48017 6.58835 8C6.58835 8.51983 6.91949 8.94143 7.32793 8.94143H16.2019C16.6103 8.94143 16.9411 8.51983 16.9411 8C16.9411 7.48017 16.6103 7.0589 16.2019 7.0589ZM16.2019 11.2942H7.32793C6.91983 11.2942 6.58835 11.7154 6.58835 12.2353C6.58835 12.7551 6.91949 13.1764 7.32793 13.1764H16.2019C16.6103 13.1764 16.9411 12.7551 16.9411 12.2353C16.9411 11.7154 16.6103 11.2942 16.2019 11.2942ZM16.2019 15.0589H7.32793C6.91983 15.0589 6.58835 15.4802 6.58835 16C6.58835 16.5198 6.91949 16.9414 7.32793 16.9414H16.2019C16.6103 16.9414 16.9411 16.5198 16.9411 16C16.9411 15.4802 16.6103 15.0589 16.2019 15.0589Z" fill="white"/>
                            </svg> : 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8.76269 6.3782C10.8057 9.90902 12.8139 13.3811 14.8613 16.9185C14.6825 16.9185 14.5604 16.9185 14.4383 16.9185C13.7362 16.9185 13.0363 16.9076 12.3342 16.925C12.0835 16.9315 11.9505 16.8488 11.8284 16.6335C10.8254 14.8778 9.80929 13.1287 8.77359 11.3361C8.6951 11.458 8.62968 11.5493 8.57299 11.6451C7.60271 13.3202 6.63024 14.9931 5.66868 16.6748C5.56184 16.8597 5.44845 16.9272 5.23695 16.925C4.4084 16.9141 3.57984 16.9206 2.66406 16.9206C4.70929 13.3876 6.72182 9.90685 8.76269 6.38038V6.3782Z" fill="white"/>
                                <path d="M10.2756 6.34967C12.2097 6.34967 14.0783 6.2844 15.9403 6.37142C17.314 6.43669 18.3192 7.22421 18.8992 8.46642C19.4813 9.71733 19.3592 10.9552 18.5394 12.0625C18.184 12.5433 17.6738 12.9088 17.1963 13.3634C17.8351 14.4729 21.2279 20.5882 21.9387 21.8174C21.8057 21.8283 21.7141 21.8435 21.6247 21.8435C20.8703 21.8435 20.1137 21.8522 19.3592 21.837C19.235 21.8348 19.0583 21.7565 19.0016 21.6586C17.9354 19.8399 14.1764 13.0872 13.1211 11.2619C13.1058 11.2358 13.1014 11.2032 13.0775 11.1249C13.4111 11.1249 13.7272 11.1249 14.0434 11.1249C14.5405 11.1249 15.0355 11.127 15.5326 11.1249C16.2303 11.1205 16.7275 10.6637 16.7515 10.0132C16.7754 9.34532 16.2979 8.83843 15.5871 8.8145C14.9722 8.79274 14.3574 8.80797 13.7425 8.80797C13.1385 8.80797 12.5345 8.8145 11.9306 8.80144C11.8281 8.80144 11.6798 8.75141 11.634 8.67744C11.1827 7.92472 10.7466 7.16113 10.2778 6.35184L10.2756 6.34967Z" fill="white"/>
                                <path d="M14.0397 22.6229C14.0397 22.3771 14.0484 22.1334 14.0637 21.892C13.4052 22.0268 12.7249 22.0964 12.0272 22.0964C6.44749 22.0964 1.90786 17.5671 1.90786 12C1.90786 6.43292 6.44749 1.90355 12.0272 1.90355C17.6069 1.90355 21.7497 6.05004 22.1181 11.2864C22.7221 11.0776 23.3501 10.9144 23.9955 10.8035C23.3915 4.74474 18.2544 0 12.0272 0C5.39653 0 0 5.38434 0 12C0 18.6157 5.39653 24 12.0272 24C12.7336 24 13.4248 23.9347 14.0986 23.8173C14.0593 23.4235 14.0397 23.0232 14.0397 22.6207V22.6229Z" fill="white"/>
                            </svg>
                        }
                    </IconButton>
                    {screenMD ? 
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'12px',
                            fontStyle:'normal',
                            fontWeight:700,
                        }}>
                            {menuTitleArr[categoryIndex]}
                        </Typography> : 
                        <React.Fragment>
                            {leftMenuTitleArr.map((item,index) => (
                                <Button variant='text' disableRipple sx={{
                                    textTransform:'none',
                                    bgcolor:'transparent',
                                    minWidth:'0px',
                                    minHeight:'0px',
                                    padding:categoryIndex >= 0 ? '25px 0px 10px 0px' : '20px 0px',
                                    color:'#FFFFFF',
                                    fontSize:'12px',
                                    fontStyle:'normal',
                                    fontWeight:700,
                                    position:'relative',
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    '&:hover': {
                                        bgcolor:'transparent',
                                    },
                                }} onClick={() => handleLeftMenuClick(index)} key={index}>
                                    {item}
                                    {categoryIndex >= 0 ? 
                                    <Box sx={{
                                        bgcolor:index === categoryIndex ? '#4AB3FF' : 'transparent',
                                        width:'44px',
                                        height:'6px',
                                        mt:'10px',
                                    }} /> : ''
                                    }  
                                </Button>
                            ))}
                        </React.Fragment>
                    }
                </Box>
                <Box sx={{
                    display:'flex',
                    justifyContent:'end',
                    alignItems:'center',
                    gap:'58px',
                }}>
                    <Button variant='text' disableRipple sx={{
                        bgcolor:'transparent',
                        textTransform:'none',
                        color:'#FFFFFF',
                        fontSize:'16px',
                        fontStyle:'normal',
                        fontWeight:400,
                        gap:'8px',
                        minWidth:'0px',
                        minHeight:'0px',
                        padding:'20px 0px',
                        '&:hover': {
                            bgcolor:'transparent',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            padding:'5px',
                        },
                    }} onClick={handleClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M11.8517 1.40724C11.488 1.36309 11.1578 1.62265 11.1119 1.98462C11.066 2.34835 11.322 2.6803 11.684 2.72974C13.0065 2.89924 14.2654 3.4007 15.3442 4.18643C12.9323 4.73556 12.1995 6.3953 12.0459 7.83257C11.8217 9.90902 13.3013 10.9985 14.2795 11.7189C14.6221 11.9713 15.1412 12.3545 15.1659 12.5328C15.1694 12.5593 15.1447 12.6458 15.0299 12.7924C13.7586 14.3956 13.2837 15.727 13.6227 16.7493C13.7269 17.0601 13.9423 17.4344 14.3784 17.7469C13.2342 18.3508 11.9594 18.6651 10.6669 18.6651C10.6051 18.6651 10.5433 18.658 10.4798 18.6562C11.3149 17.2525 11.0165 16.1913 10.5504 15.508C9.42387 13.8518 7.73941 13.338 6.15382 14.1714C6.04435 14.2314 5.9543 14.2067 5.43695 13.7211C4.92843 13.2409 4.11975 12.4922 2.93674 12.7147C2.83256 12.3139 2.74957 11.9042 2.7072 11.4805C2.66835 11.115 2.34347 10.8484 1.9762 10.8837C1.61071 10.9208 1.34232 11.2474 1.3794 11.6147C1.8526 16.3856 5.87308 20.0141 10.6669 19.9999C15.8139 19.9999 20.0003 15.8135 20.0003 10.6665C20.0003 5.9786 16.499 1.99874 11.8552 1.40724H11.8517ZM3.39405 14.0072C3.7772 14.0213 4.08443 14.2826 4.52056 14.694C5.02025 15.1655 5.77773 15.8753 6.77005 15.3544C8.08901 14.6605 9.01247 15.6246 9.44506 16.2602C9.61457 16.5092 10.0472 17.1483 8.97716 18.485C6.51226 17.9623 4.43934 16.3008 3.39228 14.0072H3.39405ZM15.6726 16.8958C15.2348 16.7705 14.9681 16.5816 14.8851 16.3326C14.7068 15.7958 15.15 14.7823 16.0717 13.624C16.4019 13.2073 16.5414 12.7818 16.4849 12.3598C16.386 11.62 15.7468 11.1485 15.0688 10.6488C14.1647 9.98141 13.2289 9.2928 13.3702 7.97736C13.485 6.90912 13.9599 5.51246 16.6349 5.35002C17.9416 6.81201 18.6637 8.70482 18.6637 10.6665C18.6637 13.1826 17.4931 15.4286 15.6726 16.8958Z" fill="white"/>
                        <path d="M4.883 11.8266C5.13726 12.0579 5.52571 12.0579 5.77997 11.8266C8.84344 9.04209 10.3319 6.80849 10.3319 4.99866C10.3319 2.24242 8.08949 0 5.33148 0C2.57348 0 0.331055 2.24242 0.331055 5.00043C0.331055 6.80849 1.81953 9.04209 4.883 11.8283V11.8266ZM5.33148 1.33309C7.35319 1.33309 8.99882 2.97871 8.99882 5.00043C8.99882 5.91505 8.34728 7.58716 5.33148 10.4264C2.31569 7.58539 1.66415 5.91329 1.66415 5.00043C1.66415 2.97871 3.30977 1.33309 5.33148 1.33309Z" fill="white"/>
                        <path d="M3.66406 5.0003C3.66406 5.92023 4.41095 6.66711 5.33087 6.66711C6.25079 6.66711 6.99768 5.92023 6.99768 5.0003C6.99768 4.08038 6.25079 3.3335 5.33087 3.3335C4.41095 3.3335 3.66406 4.08038 3.66406 5.0003Z" fill="white"/>
                        </svg>
                        {languageText}
                    </Button>
                </Box>
            </Grid>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {languageMenuItemArr.map((item,index) => (
                    <MenuItem onClick={() => handleChangeLanguage(item.language)} disableRipple sx={{
                        backgroundColor:item.languageText === languageText ? '#4AB3FF' : 'transparent',
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'0px',
                        color:item.languageText === languageText ? '#FFFFFF' : '#4AB3FF',
                        fontSize:'16px',
                        fontStyle:'normal',
                        fontWeight:400,
                        width:'100%',
                        height:'40px',
                        "&:hover": {
                            backgroundColor:item.languageText === languageText ? '#4AB3FF' : 'transparent',
                            color:item.languageText === languageText ? '#FFFFFF' : '#4AB3FF',
                        },
                    }} key={item}>
                        {item.languageText}
                    </MenuItem>
                ))}
            </StyledMenu>
            {screenMD ? 
                <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorElMenu}
                open={openMenu}
                onClose={handleCloseMenuClick}
            >
                {menuTitleArr.map((item,index) => (
                    <MenuItem onClick={() => handleLeftMenuClick(index)} disableRipple sx={{
                        backgroundColor:categoryIndex === index ? '#4AB3FF' : 'transparent',
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'0px',
                        color:categoryIndex === index ? '#FFFFFF' : '#4AB3FF',
                        fontSize:'16px',
                        fontStyle:'normal',
                        fontWeight:400,
                        width:'100%',
                        height:'40px',
                        padding:'0px 50px 0px 18px',
                        "&:hover": {
                            backgroundColor:categoryIndex === index ? '#4AB3FF' : 'transparent',
                            color:categoryIndex === index ? '#FFFFFF' : '#4AB3FF',
                        },
                    }} key={item}>
                        {item}
                    </MenuItem>
                ))}
            </StyledMenu> : ''
            }
        </React.Fragment>
    );
}

export default HeaderMenu;