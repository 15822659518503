import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import bannerIcon from '../../assets/images/api/header-banner.png';
import bannerMobileIcon from '../../assets/images/api/header-banner-mobile.png';
import image1Icon from '../../assets/images/api/header-image1.png';
import image2Icon from '../../assets/images/api/header-image2.png';
import image2MobileIcon from '../../assets/images/api/header-image2-mobile.png';

const ApiContentHeader = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        unlocking_the__data: t("unlocking_the__data"),
        we_a__growth: t("we_a__growth"),
    };
    
    return (
        <Grid sx={{
            width:'100%',
            position:'relative',
            display:'flex',
            justifyContent:'center',
            alignItems:'flex-start',
        }}>
            <CardMedia
                component="img"
                image={ screenMD ? bannerMobileIcon : bannerIcon }
                sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'calc(100% - 132px)',
                    objectFit:'cover',
                }}
            />

            <Grid container sx={{
                padding:'51px 58px 0px 58px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'48px 18px 0px 18px',
                },
            }}>
                <Container sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'start',
                    alignItems:'start',
                    position:'relative',
                    '@media': {
                        padding:'0px',
                        maxWidth:'1084px',
                    }
                }}>
                    <Box sx={{
                        padding:'0px 30px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="242" height="86" viewBox="0 0 242 86" fill="none">
                        <path d="M121.233 45.7288H115.153L113.806 42.1634C113.354 40.9827 111.268 39.9185 109.181 39.9185H103.771L101.754 45.7288H95.6738L104.518 22.7595H112.358L121.241 45.7288H121.233ZM111.79 35.957L108.434 27.1095L105.079 35.957H111.797H111.79Z" fill="white"/>
                        <path d="M129.4 45.7288H123.538V22.7595H129.4V45.7288Z" fill="white"/>
                        <path d="M139.443 45.7288H133.581V22.7595H139.443V45.7288Z" fill="white"/>
                        <path d="M153.846 32.5626H151.495C150.148 32.5626 149.253 33.2928 149.253 34.2482V45.729H143.429V32.7568C143.429 30.0303 145.485 28.6011 149.362 28.6011H153.838V32.5626H153.846Z" fill="white"/>
                        <path d="M175.232 41.8296C175.232 44.4163 173.216 45.729 169.892 45.729H162.316C158.735 45.729 157.015 44.3541 157.015 41.9927V32.4772C157.015 29.976 158.844 28.6011 162.387 28.6011H169.814C173.06 28.6011 175.225 29.9216 175.225 32.617V41.8296H175.232ZM169.409 34.7842C169.409 33.4948 167.766 32.5626 165.641 32.5626H162.838V41.7441H169.409V34.7842Z" fill="white"/>
                        <path d="M197.436 42.0547C197.436 44.5248 195.645 45.7366 192.36 45.7366H184.076C180.565 45.7366 179.109 44.5869 179.109 42.1401V28.6086H184.933V40.4623C184.933 41.3012 185.532 41.7517 186.685 41.7517H189.744C190.935 41.7517 191.613 41.2779 191.613 40.4312V28.6086H197.436V42.0624V42.0547Z" fill="white"/>
                        <path d="M219.905 45.729H214.081V35.1726C214.081 33.7122 212.213 32.5626 210.165 32.5626H207.067V45.729H201.243V32.5004C201.243 29.9759 203.33 28.5933 206.771 28.5933H214.463C217.561 28.5933 219.912 29.9682 219.912 32.6946V45.7212L219.905 45.729Z" fill="white"/>
                        <path d="M242 41.5498C242 44.2996 239.913 45.7366 236.324 45.7366H229.193C225.573 45.7366 223.634 44.4161 223.634 41.8916V33.1606C223.634 30.1856 225.542 28.6087 229.31 28.6087H236.176V22.7673H242V41.5498ZM236.176 41.744V34.9472C236.176 33.6267 234.199 32.5625 232.105 32.5625H229.458V41.744H236.176Z" fill="white"/>
                        <path d="M115.402 74.0659C115.402 76.6215 112.942 78.0818 109.244 78.0818H96.8104V74.0969H109.578V71.0054C109.578 69.6305 107.671 68.5041 105.063 68.5041H102.112C98.4531 68.5041 96.7715 67.3545 96.7715 64.8533V58.9809C96.7715 56.4796 98.71 55.1047 102.517 55.1047H115.394V59.0663H102.595V64.5426H109.757C113.23 64.5426 115.394 65.9718 115.394 68.5041V74.0659H115.402Z" fill="white"/>
                        <path d="M139.404 73.6773C139.404 76.6213 137.092 78.0816 132.872 78.0816H125.484C121.155 78.0816 118.952 76.5358 118.952 73.6773V59.7186C118.952 56.8523 121.078 55.1123 125.523 55.1123H132.95C136.757 55.1123 139.404 56.7435 139.404 59.7186V73.6773ZM133.581 62.2198C133.581 60.4255 131.455 59.0739 129.065 59.0739H124.776V74.0967H133.581V62.2198Z" fill="white"/>
                        <path d="M161.421 68.5118H149.027V78.0894H143.204V59.8973C143.204 56.7514 145.555 55.1201 149.736 55.1201H162.2V59.0817H153.13C150.779 59.0817 149.027 60.3478 149.027 61.6373V64.558H161.421V68.5195V68.5118Z" fill="white"/>
                        <path d="M182.356 59.0739H176.089V78.0816H169.853V62.1033C169.853 60.3322 167.688 59.0739 165.259 59.0739H163.578V55.1123H182.356V59.0739Z" fill="white"/>
                        <path d="M31.2581 22.7673C38.553 35.3744 45.7233 47.7718 53.0338 60.4022C52.3954 60.4022 51.9594 60.4022 51.5234 60.4022C49.0165 60.4022 46.5174 60.3633 44.0105 60.4255C43.1152 60.4488 42.6403 60.1536 42.2043 59.3846C38.623 53.116 34.9951 46.8707 31.297 40.4701C31.0167 40.9051 30.7832 41.2313 30.5808 41.5731C27.1163 47.5543 23.644 53.5277 20.2107 59.5322C19.8292 60.1925 19.4243 60.4333 18.6692 60.4255C15.7107 60.3867 12.7523 60.41 9.48242 60.41C16.7851 47.7951 23.971 35.3667 31.2581 22.7751V22.7673Z" fill="white"/>
                        <path d="M36.6533 22.6662C43.5589 22.6662 50.231 22.4331 56.8797 22.7438C61.7845 22.9769 65.3735 25.7888 67.4444 30.2242C69.5231 34.6907 69.0872 39.1105 66.1599 43.0643C64.8908 44.781 63.0691 46.086 61.3641 47.7095C63.6452 51.671 75.7592 73.5062 78.2972 77.895C77.8223 77.9339 77.4953 77.9882 77.1761 77.9882C74.4824 77.9882 71.7809 78.0193 69.0871 77.9649C68.6434 77.9572 68.0128 77.6775 67.8103 77.328C64.0033 70.8341 50.5813 46.723 46.8132 40.2058C46.7587 40.1126 46.7432 39.9961 46.6575 39.7164C47.8487 39.7164 48.9776 39.7164 50.1064 39.7164C51.8815 39.7164 53.6488 39.7242 55.4238 39.7164C57.9152 39.7009 59.6902 38.0697 59.7759 35.7471C59.8615 33.3624 58.1565 31.5525 55.6185 31.4671C53.423 31.3894 51.2275 31.4437 49.032 31.4437C46.8755 31.4437 44.719 31.4671 42.5624 31.4204C42.1965 31.4204 41.6671 31.2418 41.5036 30.9777C39.892 28.29 38.335 25.5635 36.6611 22.6739L36.6533 22.6662Z" fill="white"/>
                        <path d="M50.13 80.7771C50.13 79.8993 50.1611 79.0293 50.2156 78.1671C47.8644 78.6487 45.4354 78.8973 42.9441 78.8973C23.0213 78.8973 6.81219 62.7248 6.81219 42.847C6.81219 22.9693 23.0213 6.7968 42.9441 6.7968C62.8668 6.7968 77.659 21.6022 78.9747 40.2992C81.1313 39.5535 83.3735 38.9709 85.6779 38.5748C83.5214 16.9415 65.1791 0 42.9441 0C19.2688 0 0 19.2252 0 42.847C0 66.4688 19.2688 85.6941 42.9441 85.6941C45.4665 85.6941 47.9345 85.461 50.3402 85.0416C50.2 83.6356 50.13 82.2063 50.13 80.7693V80.7771Z" fill="white"/>
                        </svg>
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'48px',
                            fontWeight:700,
                            lineHeight:'62px',
                            maxWidth:'823px',
                            mt:'35px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'24px',
                                lineHeight:'31px',
                                maxWidth:'100%',
                            },
                        }}>
                            {textData.unlocking_the__data}
                        </Typography>
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'20px',
                            fontWeight:400,
                            lineHeight:'29px',
                            maxWidth:'703px',
                            mt:'18px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'12px',
                                lineHeight:'18px',
                                maxWidth:'100%',
                            },
                        }}>
                            {textData.we_a__growth}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'end',
                        alignItems:'end',
                        mt:'102px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            mt:'82px',
                        },
                    }}>
                        <CardMedia
                            component="img"
                            image={ image1Icon }
                            sx={{
                                width:'631px',
                                height:'380px',
                                objectFit:'cover',
                                zIndex:2,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'100%',
                                    height:'calc((100vw - 36px) * 233 / 339)',
                                },
                            }}
                        />
                        <CardMedia
                            component="img"
                            image={ screenMD ? image2MobileIcon : image2Icon }
                            sx={{
                                width:'636px',
                                height:'473px',
                                objectFit:'cover',
                                zIndex:1,
                                ml:'-185px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    ml:'0px',
                                    mt:'18px',
                                    width:'100%',
                                    height:'calc((100vw - 36px) * 287 / 339)',
                                },
                            }}
                        />
                    </Box>
                </Container>
            </Grid>
        </Grid>
    );
}

export default ApiContentHeader;