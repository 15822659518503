import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../component/header/Header';
import ApiContent from '../component/api/ApiContent';
import { useLocation } from 'react-router-dom';

const Api = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const location = useLocation();
    const isContactSales = location.state && location.state.isContactSales;
    
    return (
        <React.Fragment>
            <Header categoryIndex={screenMD ? 2 : 1}/>
            <ApiContent isContactSales={isContactSales} />
        </React.Fragment>
    );
}

export default Api;