import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import imageIcon5 from '../../assets/images/home/home-content-image5.png';


const HomeContentMainContent4 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        real_time_synchronization: t("real_time_synchronization"),
        accurate: t("accurate"),
        timely: t("timely"),
        real_time__updates: t("real_time__updates"),
        the_real__adjustments: t("the_real__adjustments"),
    };

    // const handleFormatTime = () => {
    //     // 创建一个Date对象
    //     const currentDate = new Date();

    //     // 月份的英文缩写数组
    //     const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    //     // 获取年、月、日
    //     const year = currentDate.getFullYear();
    //     const month = months[currentDate.getMonth()];
    //     const day = currentDate.getDate();

    //     // 构建输出字符串
    //     const formattedDate = month + ' ' + day + ', ' + year;
    //     return formattedDate;
    // }

    return (
        <Grid sx={{
            position:'relative',
            width:'100%',
            height:'500px',
            mt:'158px',
            padding:'86px 128px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'88px 18px 270px 18px',
                height:'auto',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
            },
        }}>
            <CardMedia
                component="img"
                image={ imageIcon5 }
                sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    objectFit:'cover',
                    border:'none',
                }}
            />
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:'100%',
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    zIndex:1,
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'10px',
                        fontWeight:500,
                        textTransform:'uppercase',
                    }}>
                        {textData.real_time_synchronization}
                    </Typography>
                    <Box sx={{
                        color:'#FFFFFF',
                        fontSize:'8px',
                        fontWeight:500,
                        textTransform:'uppercase',
                        border:'1px solid #FFFFFF',
                        borderRadius:'50px',
                        padding:'3px 4px',
                        ml:'6px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            padding:'3px 8px',
                            ml:'10px',
                        },
                    }}>
                        {textData.accurate}
                    </Box>
                    <Box sx={{
                        color:'#FFFFFF',
                        fontSize:'8px',
                        fontWeight:500,
                        textTransform:'uppercase',
                        border:'1px solid #FFFFFF',
                        borderRadius:'50px',
                        padding:'3px 4px',
                        ml:'4px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            padding:'3px 8px',
                            ml:'10px',
                        },
                    }}>
                        {textData.timely}
                    </Box>
                </Box>
                <Box sx={{
                    bgcolor:'#FFFFFF',
                    width:'319px',
                    height:'1px',
                    mt:'10px',
                    zIndex:1,
                }} />
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'47px',
                    fontWeight:500,
                    width:'900px',
                    textTransform:'uppercase',
                    lineHeight:'53px',
                    mt:'8px',
                    zIndex:1,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'18px',
                        fontSize:'32px',
                        width:'100%',
                        lineHeight:'46px',
                    },
                }}>
                    {textData.real_time__updates}
                </Typography>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'14px',
                    fontWeight:400,
                    width:'373px',
                    lineHeight:'19px',
                    mt:'32px',
                    zIndex:1,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'18px',
                        fontSize:'12px',
                        width:'100%',
                    },
                }}>
                    {textData.the_real__adjustments}
                </Typography>
            </Box>
        </Grid>
    );
}

export default HomeContentMainContent4;