import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import HeaderMenu from './HeaderMenu';

const Header = (props) => {
    const {
        categoryIndex,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const [ scrollY, setScrollY ] = useState(0);

    const handleScroll = () => {
        setScrollY(Math.abs(window.scrollY));
    };
    const calculateOpacity = () => {
        return Math.min(1, scrollY / 60);
    };
    useEffect(() => {
        if(screenMD) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <AppBar sx={{
            background:'linear-gradient(90deg, #09157D 0%, #2F94FF 100%)',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                // background:categoryIndex === 0 ? (scrollY >= 60 ? 'linear-gradient(90deg, #09157D 0%, #2F94FF 100%)' : 'none') : 'linear-gradient(90deg, #09157D 0%, #2F94FF 100%)',
                boxShadow:'none',
                background: categoryIndex === 0
                ? `linear-gradient(90deg, rgba(9, 21, 125, ${calculateOpacity()}) 0%, rgba(47, 148, 255, ${calculateOpacity() * 100}%) 100%)`
                : 'linear-gradient(90deg, rgba(9, 21, 125, 1) 0%, rgba(47, 148, 255, 1) 100%)',
            },
        }}>
            <Toolbar sx={{
                    display:'flex',
                    padding:'0px 128px',
                    justifyContent:'center',
                    alignItems:'center',
                    height:'88px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        padding:'0px',
                        height:'60px',
                    },
                }}>
                    <Container sx={{
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'center',
                        height:'100%',
                        '@media': {
                            pl:'0px',
                            pr:'0px',
                            maxWidth:'1000px',
                        }
                    }}>
                        <HeaderMenu 
                            categoryIndex={categoryIndex}
                        />
                    </Container>
            </Toolbar>
        </AppBar>
    );
}

export default Header;