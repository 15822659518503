import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import facebookIcon from '../../assets/images/footer/footer-facebook.png';
import instagramIcon from '../../assets/images/footer/footer-instagram.png';
import twitterIcon from '../../assets/images/footer/footer-twitter.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        follow_us: t("follow_us"),
        use_your__more: t("use_your__more"),
    };

    const contactItemArr = [
        {
            image:facebookIcon,
            title:'Facebook',
        },
        {
            image:instagramIcon,
            title:'Instagram',
        },
        {
            image:twitterIcon,
            title:'Twitter',
        },
    ];
    
    return (
        <Grid container sx={{
            bgcolor:'#333650',
            padding:'48px 68px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'48px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    flexDirection:'column',
                },
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="207" height="74" viewBox="0 0 207 74" fill="none">
                <path d="M103.639 39.8351H98.441L97.2896 36.7872C96.9036 35.7778 95.1199 34.8681 93.3362 34.8681H88.7107L86.987 39.8351H81.7891L89.3496 20.1995H96.0517L103.646 39.8351H103.639ZM95.5658 31.4815L92.6973 23.9181L89.8288 31.4815H95.5725H95.5658Z" fill="white"/>
                <path d="M110.62 39.8351H105.608V20.1995H110.62V39.8351Z" fill="white"/>
                <path d="M119.206 39.8351H114.194V20.1995H119.206V39.8351Z" fill="white"/>
                <path d="M131.519 28.5797H129.509C128.358 28.5797 127.593 29.2039 127.593 30.0207V39.8352H122.614V28.7457C122.614 26.4149 124.371 25.1931 127.686 25.1931H131.513V28.5797H131.519Z" fill="white"/>
                <path d="M149.801 36.5017C149.801 38.713 148.078 39.8352 145.236 39.8352H138.76C135.698 39.8352 134.228 38.6598 134.228 36.6412V28.5067C134.228 26.3685 135.792 25.1931 138.82 25.1931H145.169C147.944 25.1931 149.795 26.322 149.795 28.6262V36.5017H149.801ZM144.823 30.4789C144.823 29.3766 143.419 28.5797 141.602 28.5797H139.206V36.4287H144.823V30.4789Z" fill="white"/>
                <path d="M168.783 36.6942C168.783 38.8059 167.252 39.8418 164.444 39.8418H157.362C154.361 39.8418 153.116 38.859 153.116 36.7673V25.1997H158.094V35.3329C158.094 36.0501 158.607 36.4353 159.592 36.4353H162.208C163.226 36.4353 163.805 36.0302 163.805 35.3064V25.1997H168.783V36.7009V36.6942Z" fill="white"/>
                <path d="M187.99 39.8352H183.012V30.8109C183.012 29.5625 181.415 28.5798 179.664 28.5798H177.015V39.8352H172.037V28.5266C172.037 26.3685 173.821 25.1865 176.762 25.1865H183.338C185.987 25.1865 187.997 26.3619 187.997 28.6927V39.8286L187.99 39.8352Z" fill="white"/>
                <path d="M206.879 36.2628C206.879 38.6135 205.095 39.8419 202.027 39.8419H195.931C192.836 39.8419 191.179 38.7131 191.179 36.555V29.0911C191.179 26.5479 192.809 25.1999 196.031 25.1999H201.901V20.2063H206.879V36.2628ZM201.901 36.4288V30.6184C201.901 29.4896 200.21 28.5798 198.42 28.5798H196.157V36.4288H201.901Z" fill="white"/>
                <path d="M98.655 64.0595C98.655 66.2442 96.5519 67.4926 93.3905 67.4926H82.7618V64.0861H93.6767V61.4432C93.6767 60.2679 92.0461 59.305 89.8165 59.305H87.2941C84.1661 59.305 82.7285 58.3222 82.7285 56.184V51.1639C82.7285 49.0257 84.3857 47.8503 87.6402 47.8503H98.6483V51.2369H87.7068V55.9184H93.8298C96.7981 55.9184 98.6483 57.1403 98.6483 59.305V64.0595H98.655Z" fill="white"/>
                <path d="M119.173 63.7277C119.173 66.2444 117.197 67.4928 113.589 67.4928H107.273C103.573 67.4928 101.689 66.1714 101.689 63.7277V51.7949C101.689 49.3446 103.506 47.8572 107.307 47.8572H113.656C116.91 47.8572 119.173 49.2517 119.173 51.7949V63.7277ZM114.195 53.9331C114.195 52.3992 112.378 51.2438 110.335 51.2438H106.668V64.0863H114.195V53.9331Z" fill="white"/>
                <path d="M137.996 59.3116H127.4V67.4992H122.422V51.9474C122.422 49.258 124.432 47.8635 128.006 47.8635H138.661V51.2501H130.908C128.898 51.2501 127.4 52.3325 127.4 53.4348V55.9316H137.996V59.3182V59.3116Z" fill="white"/>
                <path d="M155.891 51.2438H150.533V67.4928H145.202V53.8335C145.202 52.3195 143.352 51.2438 141.275 51.2438H139.838V47.8572H155.891V51.2438Z" fill="white"/>
                <path d="M26.7207 20.2058C32.9569 30.9832 39.0865 41.5812 45.336 52.3785C44.7902 52.3785 44.4175 52.3785 44.0448 52.3785C41.9018 52.3785 39.7654 52.3453 37.6223 52.3984C36.8569 52.4184 36.451 52.166 36.0783 51.5086C33.0168 46.1498 29.9153 40.811 26.754 35.3393C26.5144 35.7111 26.3147 35.99 26.1417 36.2822C23.18 41.3953 20.2117 46.5018 17.2767 51.6348C16.9505 52.1992 16.6045 52.4051 15.9589 52.3984C13.4298 52.3652 10.9008 52.3852 8.10547 52.3852C14.3483 41.6012 20.4912 30.9765 26.7207 20.2125V20.2058Z" fill="white"/>
                <path d="M31.333 20.1198C37.2364 20.1198 42.9401 19.9206 48.6238 20.1862C52.8167 20.3854 55.8849 22.7893 57.6552 26.5809C59.4322 30.3991 59.0595 34.1775 56.5571 37.5575C55.4723 39.025 53.9149 40.1406 52.4574 41.5284C54.4074 44.915 64.7633 63.5812 66.9329 67.333C66.5269 67.3662 66.2474 67.4127 65.9745 67.4127C63.6718 67.4127 61.3623 67.4393 59.0595 67.3928C58.6802 67.3861 58.1411 67.1471 57.9681 66.8483C54.7135 61.2969 43.2396 40.6851 40.0183 35.1138C39.9718 35.0341 39.9584 34.9345 39.8852 34.6955C40.9035 34.6955 41.8686 34.6955 42.8336 34.6955C44.351 34.6955 45.8618 34.7021 47.3793 34.6955C49.509 34.6822 51.0264 33.2877 51.0996 31.3022C51.1729 29.2636 49.7153 27.7164 47.5456 27.6434C45.6688 27.577 43.792 27.6235 41.9151 27.6235C40.0716 27.6235 38.228 27.6434 36.3845 27.6035C36.0717 27.6035 35.6191 27.4508 35.4793 27.225C34.1017 24.9275 32.7706 22.5967 31.3397 20.1265L31.333 20.1198Z" fill="white"/>
                <path d="M42.8543 69.7966C42.8543 69.0463 42.881 68.3025 42.9276 67.5655C40.9176 67.9772 38.8411 68.1897 36.7114 68.1897C19.6801 68.1897 5.82351 54.3644 5.82351 37.3716C5.82351 20.3788 19.6801 6.55351 36.7114 6.55351C53.7426 6.55351 66.388 19.2101 67.5127 35.1935C69.3563 34.5561 71.2731 34.058 73.2431 33.7194C71.3995 15.2259 55.7193 0.743164 36.7114 0.743164C16.4722 0.743164 0 17.1781 0 37.3716C0 57.565 16.4722 74 36.7114 74C38.8677 74 40.9775 73.8008 43.034 73.4422C42.9142 72.2403 42.8543 71.0185 42.8543 69.79V69.7966Z" fill="white"/>
                </svg>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    ml:'89px',
                    width:'286px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        ml:'0px',
                        width:'100%',
                        mt:'48px',
                    },
                }}>
                    <Typography sx={{
                        color:'#858BBE',
                        fontSize:'24px',
                        fontWeight:700,
                    }}>
                        {textData.follow_us}
                    </Typography>
                    <Box sx={{
                        mt:'28px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        gap:'18px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            mt:'8px',
                        },
                    }}>
                        {contactItemArr.map((item,index) => (
                            <Box sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'center',
                                gap:'10px',
                            }} key={index}>
                                <CardMedia
                                    component="img"
                                    image={ item.image }
                                    sx={{
                                        width:'28px',
                                        height:'28px',
                                        objectFit:'contain',
                                    }}
                                />
                                <Typography sx={{
                                    color:'#ACB3D7',
                                    fontSize:'18px',
                                    fontWeight:700,
                                }}>
                                    {item.title}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    ml:'48px',
                    width:'433px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        ml:'0px',
                        width:'100%',
                        mt:'18px',
                    },
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontWeight:400,
                    }}>
                        {textData.use_your__more}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'12px',
                        fontWeight:700,
                        mt:'18px',
                    }}>
                        {'© 2023 AllroundSoft'}
                    </Typography>
                </Box>
            </Container>
        </Grid>
    );
}

export default Footer;