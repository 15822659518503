import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from 'react-i18next';
import image21Icon from '../../assets/images/home/home-content-image21.png';

const HomeContentMainRealtime = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        realtime: t("realtime"),
        data_server_distribution: t("data_server_distribution"),
    };

    return (
        <Grid sx={{
            mt:'88px',
            width:'100%',
            padding:'0px 128px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#4AB3FF',
                    fontSize:'24px',
                    fontWeight:700,
                    lineHeight:'26px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'18px',
                    },
                }}> 
                    {textData.realtime}
                </Typography>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'36px',
                    fontWeight:700,
                    lineHeight:'46px',
                    textTransform:'uppercase',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'32px',
                    },
                }}> 
                    {textData.data_server_distribution}
                </Typography>
                <CardMedia 
                    component="img"
                    image={ image21Icon }
                    sx={{
                        width:'1000px',
                        height:'550px',
                        objectFit:'cover',
                        mt:'28px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'100%',
                            height:'calc((100vw - 36px) * 550 / 1000)',
                        },
                    }}
                />
            </Container>
        </Grid>
    );
}

export default HomeContentMainRealtime;