import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const ServiceContentMainContent3 = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        data_visualization__reporting: t("data_visualization__reporting"),
        customized_reports__insights: t("customized_reports__insights"),
        we_thoroughly__focus: t("we_thoroughly__focus"),
        we_place__presentations: t("we_place__presentations"),
        our_professional__analysis: t("our_professional__analysis"),
        we_help__maps: t("we_help__maps"),
        our_customized__updates: t("our_customized__updates"),
    };

    const itemArr = [
        textData.we_thoroughly__focus,
        textData.we_place__presentations,
        textData.our_professional__analysis,
        textData.we_help__maps,
        textData.our_customized__updates,
    ];

    return (
        <Grid sx={{
            width:'100%',
            mt:'88px',
            padding:'0px 88px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'48px',
                padding:'0px 18px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',                
                '@media': {
                    padding:'0px',
                    maxWidth:'1024px',
                }
            }}>
                <Typography sx={{
                    color:'#0F1962',
                    fontSize:'36px',
                    fontWeight:600,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'32px',
                    },
                }}>
                    {textData.data_visualization__reporting}
                </Typography>
                <Typography sx={{
                    color:'#666C89',
                    fontSize:'15px',
                    fontWeight:500,
                    whiteSpace:'pre-line',
                    mt:'18px',
                }}>
                    {textData.customized_reports__insights}
                </Typography>
                {itemArr.map((item,index) => (
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',  
                        mt:'28px',
                    }} key={index}>
                        <Box sx={{
                            mt:'3px',
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
                        <path d="M7.99105 1.07043L13.4598 6.57174M13.4598 6.57174L7.99105 12.073M13.4598 6.57174H1.61084" stroke="#1C1F35" stroke-width="1.26953" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </Box>
                        <Typography sx={{
                            color:'#1C1F35',
                            fontSize:'17px',
                            fontWeight:400,
                            whiteSpace:'pre-line',
                            maxWidth:'788px',
                            ml:'16px',
                        }}>
                            {item}
                        </Typography>
                    </Box>
                ))}
            </Container>
        </Grid>
    );
}

export default ServiceContentMainContent3;